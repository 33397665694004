import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';
import WTag from "@/components/wrappers/w-tag.vue";
import UserCell from "@/components/user/UserCell.vue";
import Icon from "@/components/icon/Icon.vue";
import Loader from "@/components/loader/Loader.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import SpeachCell from "@/components/speach/SpeachCell.vue";
import WDate from "@/components/wrappers/w-date.vue";
import { confirmation } from "@/util/dialog";
import { deleteSpeach } from "@/util/routeUtil";
import axios from "axios";
import dayjs from "dayjs";
import { debounce } from "lodash";
import WDialog from "@/components/wrappers/w-dialog.vue";
import BatchUpdateSpeach from "@/components/speach/BatchUpdateSpeach.vue";
import ManagePeriodicReviewers from "@/components/speach/ManagePeriodicReviewers.vue";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import { PeriodicReviewOperation } from "@/models/periodicReviewOperation.enum";
import WInput from "@/components/form/w-input.vue";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import WDatePickerRange from "@/components/form/w-date-picker-range.vue";
import { VisibilityEnum } from "@/models/visibilityEnum";
let AdminListPeriodicReviews = class AdminListPeriodicReviews extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "tableRef", void 0);
    _defineProperty(this, "filterTitle", void 0);
    _defineProperty(this, "filterAuthor", void 0);
    _defineProperty(this, "filterAction", void 0);
    _defineProperty(this, "isLoaded", false);
    _defineProperty(this, "fixeBar", false);
    _defineProperty(this, "infiniteLoadingEnd", false);
    _defineProperty(this, "busyInfiniteLoading", false);
    _defineProperty(this, "filterSort", "LAST_MAJ");
    _defineProperty(this, "filterByTitle", '');
    _defineProperty(this, "filterByAuthor", '');
    _defineProperty(this, "filterByAction", '');
    _defineProperty(this, "filterOrder", "DESC");
    _defineProperty(this, "calendarRange", null);
    _defineProperty(this, "calendarRangeReview", null);
    // noinspection JSMismatchedCollectionQueryUpdate: the listSpeaches parameter is used as data for <el-table> line 44
    _defineProperty(this, "listSpeaches", []);
    _defineProperty(this, "gridID", new Date().getTime());
    _defineProperty(this, "selectedSpeach", null);
    _defineProperty(this, "currentPage", 1);
    _defineProperty(this, "totalSpeaches", 0);
    _defineProperty(this, "dateFormat", 'YYYY-MM-DD');
    _defineProperty(this, "managePeriodicReviewers", false);
    _defineProperty(this, "filterIsExpanded", false);
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/admin/speach');
    _defineProperty(this, "debouncedSearch", debounce(this.reinitSearch, 500));
    _defineProperty(this, "debounceInfinite", debounce($state => {
      this.infiniteHandler($state);
    }, 250));
  }
  async mounted() {
    this.filterByTitle = this.filterTitle;
    this.filterByAuthor = this.filterAuthor;
    this.filterByAction = this.filterAction;
    this.reinitSearch();
  }
  get PeriodicReviewOperation() {
    return PeriodicReviewOperation;
  }
  get availableActions() {
    return [{
      id: "NONE",
      name: this.$t('lang.select.none')
    }, {
      id: PeriodicReviewOperation.NO_REVISION,
      name: this.$t('admin.dashboard.speaches.periodicReview.filters.action.noRevision')
    }, {
      id: PeriodicReviewOperation.REVISION_NEEDED,
      name: this.$t('admin.dashboard.speaches.periodicReview.filters.action.revisionNeeded')
    }, {
      id: PeriodicReviewOperation.ARCHIVE,
      name: this.$t('admin.dashboard.speaches.periodicReview.filters.action.archive')
    }];
  }
  get VisibilityEnum() {
    return VisibilityEnum;
  }
  visibilityTag(visibility) {
    let visibilityLabel = this.$t('admin.dashboard.speaches.visibility.public');
    if (visibility === VisibilityEnum.PRIVE) {
      visibilityLabel = this.$t('admin.dashboard.speaches.visibility.private');
    } else if (visibility === VisibilityEnum.SEMI_PRIVE) {
      visibilityLabel = this.$t('admin.dashboard.speaches.visibility.semiPrivate');
    } else if (visibility === VisibilityEnum.RESTREINT) {
      visibilityLabel = this.$t('admin.dashboard.speaches.visibility.restricted');
    }
    return visibilityLabel;
  }
  getNextPeriodicReviewDateFormatted(speach) {
    const nextPRDate = dayjs(speach.speachMetadata.nextPeriodicReviewTimestamp);
    return nextPRDate.format(this.dateFormat);
  }
  reinitSearch() {
    this.currentPage = 1;
    this.infiniteLoadingEnd = false;
    this.listSpeaches = [];
    this.totalSpeaches = 0;
    this.gridID = new Date().getTime();
    this.getListSpeaches().then(pageW => {
      this.listSpeaches.push(...pageW.result);
      this.totalSpeaches = pageW.total;
      this.isLoaded = true;
    });
  }
  // noinspection JSUnusedLocalSymbols : This method is used in the template
  getSpeachAuthor(speach) {
    return {
      admin: false,
      anonymous: false,
      approver: false,
      aiEditor: false,
      customFieldValues: [],
      email: speach.authorsInfos.author.email,
      firstName: speach.authorsInfos.author.label,
      groups: [],
      id: speach.authorsInfos.author.id,
      img: speach.authorsInfos.author.imgUrl,
      lastActiveDate: 0,
      lastName: '',
      trainer: false,
      userID: '',
      createdAt: 0 // unused fake data
    };
  }
  changeSelectedAction(newAction) {
    if (!this.isLoaded) {
      return;
    }
    if (newAction === 'NONE') {
      this.filterByAction = '';
    } else {
      this.filterByAction = newAction;
    }
    this.reinitSearch();
  }
  changeCalendarRange(newCalendarRange) {
    if (!this.isLoaded) {
      return;
    }
    this.calendarRange = newCalendarRange;
    this.reinitSearch();
  }
  changeCalendarRangeReview(newCalendarRange) {
    if (!this.isLoaded) {
      return;
    }
    this.calendarRangeReview = newCalendarRange;
    this.reinitSearch();
  }
  onFixChange(value) {
    this.fixeBar = value;
  }
  clearFilterByTitle() {
    this.filterByTitle = '';
    this.reinitSearch();
  }
  clearFilterByAuthor() {
    this.filterByAuthor = '';
    this.reinitSearch();
  }
  clearFilterByAction() {
    this.filterByAction = '';
    this.reinitSearch();
  }
  clearFilterByCalendarRange() {
    this.calendarRange = null;
    this.reinitSearch();
  }
  clearFilterByCalendarRangeReview() {
    this.calendarRangeReview = null;
    this.reinitSearch();
  }
  clearAllFilters() {
    this.filterByTitle = '';
    this.filterByAuthor = '';
    this.filterByAction = '';
    this.calendarRange = null;
    this.calendarRangeReview = null;
    this.reinitSearch();
  }
  openReviewersManager(speach) {
    // unselect speach (destroy the component) if not there is multiple update (on change)
    this.selectedSpeach = null;
    // next frame reinit le component with the new speach
    this.$nextTick(() => {
      this.selectedSpeach = speach;
      this.managePeriodicReviewers = true;
    });
  }
  updateReviewers() {
    WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
    this.managePeriodicReviewers = false;
    this.reinitSearch();
  }
  closeReviewersManager() {
    this.managePeriodicReviewers = false;
  }
  archiveSpeach(speach) {
    confirmation(this.$t('thumbnail.menu.archiveConfirm'), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(() => {
      deleteSpeach(speach);
    });
  }
  async getListSpeaches() {
    const result = await axios.get(this.BASE_API_SPEACHES, {
      params: {
        page: this.currentPage,
        sort: this.filterSort,
        searchTitle: this.filterByTitle !== '' ? this.filterByTitle : null,
        searchAuthor: this.filterByAuthor !== '' ? this.filterByAuthor : null,
        periodicReviewAction: this.filterByAction !== 'NONE' ? this.filterByAction : null,
        periodicReview: true,
        startDate: this.calendarRange ? dayjs(this.calendarRange[0]).format(this.dateFormat) : null,
        endDate: this.calendarRange ? dayjs(this.calendarRange[1]).format(this.dateFormat) : null,
        startDateReview: this.calendarRangeReview ? dayjs(this.calendarRangeReview[0]).format(this.dateFormat) : null,
        endDateReview: this.calendarRangeReview ? dayjs(this.calendarRangeReview[1]).format(this.dateFormat) : null,
        asc: this.filterOrder === "ASC"
      }
    });
    return result.data;
  }
  infiniteHandler($state) {
    // fix an issue with eltable, when scroll down, that trig infinite scroll all time
    const page = document.getElementById('page');
    page.scrollTop = page.scrollTop - 100;
    // end of ugly fix
    this.busyInfiniteLoading = true;
    this.infiniteLoadingEnd = false;
    this.currentPage++;
    this.getListSpeaches().then(pageW => {
      if (pageW.result.length > 0) {
        this.listSpeaches.push(...pageW.result);
        this.totalSpeaches = pageW.total;
        $state.loaded();
      } else {
        if (pageW.total === 0) {
          this.listSpeaches = [];
          this.totalSpeaches = 0;
        }
        this.infiniteLoadingEnd = true;
        $state.complete();
      }
      this.busyInfiniteLoading = false;
      this.isLoaded = true;
    });
  }
};
__decorate([Ref()], AdminListPeriodicReviews.prototype, "tableRef", void 0);
__decorate([Prop({
  default: ''
})], AdminListPeriodicReviews.prototype, "filterTitle", void 0);
__decorate([Prop({
  default: ''
})], AdminListPeriodicReviews.prototype, "filterAuthor", void 0);
__decorate([Prop({
  default: ''
})], AdminListPeriodicReviews.prototype, "filterAction", void 0);
AdminListPeriodicReviews = __decorate([Component({
  components: {
    WDatePickerRange,
    WSelectAdvanced,
    WInput,
    ManagePeriodicReviewers,
    BatchUpdateSpeach,
    WDialog,
    WDate,
    SpeachCell,
    WToolTip,
    Loader,
    Icon,
    UserCell,
    WTag
  }
})], AdminListPeriodicReviews);
export default AdminListPeriodicReviews;