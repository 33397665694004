import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _SpeachGrid;
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
var SpeachGrid_1;
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Loader from "@/components/loader/Loader.vue";
import axios from "axios";
import InfiniteLoading from 'vue-infinite-loading';
import StandardSpeachCard from "@/components/card/StandardSpeachCard.vue";
import Icon from "@/components/icon/Icon.vue";
import AddCard from "@/components/card/AddCard.vue";
import { debounce } from "lodash";
import draggable from 'vuedraggable';
import { DateFilterEnum } from "@/models/DateFilterEnum";
let SpeachGrid = SpeachGrid_1 = (_SpeachGrid = class SpeachGrid extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "url", void 0);
    _defineProperty(this, "filter", void 0);
    _defineProperty(this, "forceInline", void 0);
    _defineProperty(this, "lineSpace", void 0);
    _defineProperty(this, "emptyState", void 0);
    _defineProperty(this, "publishFilter", void 0);
    _defineProperty(this, "changeOrderFilter", void 0);
    _defineProperty(this, "defaultFilterEtat", void 0);
    _defineProperty(this, "addCard", void 0);
    _defineProperty(this, "urlAddCard", void 0);
    _defineProperty(this, "labelAddCard", void 0);
    _defineProperty(this, "addCardIcon", void 0);
    _defineProperty(this, "loadRegulated", void 0);
    _defineProperty(this, "commentsEnabled", void 0);
    _defineProperty(this, "addButton", void 0);
    _defineProperty(this, "removeButton", void 0);
    _defineProperty(this, "filterByName", void 0);
    _defineProperty(this, "draggable", void 0);
    _defineProperty(this, "dateFilter", void 0);
    _defineProperty(this, "isOwnerOfTheList", void 0);
    _defineProperty(this, "listOfSpeach", []);
    _defineProperty(this, "currentPage", 0);
    _defineProperty(this, "totalSpeach", 0);
    _defineProperty(this, "dateFilterLabel", SpeachGrid_1.DEFAULT_DATE_FILTER_LABEL);
    _defineProperty(this, "filterName", "");
    _defineProperty(this, "selectedFilterSort", "");
    _defineProperty(this, "filterEtat", "");
    _defineProperty(this, "filterOrder", "DESC");
    _defineProperty(this, "fixeBar", false);
    _defineProperty(this, "infiniteLoadingEnd", false);
    _defineProperty(this, "gridID", new Date().getTime());
    _defineProperty(this, "confirmRemoveSpeachId", '');
    _defineProperty(this, "confirmRemoveAccess", true);
    _defineProperty(this, "debouncedSearch", debounce(this.reinitSearch, 500));
  }
  mounted() {
    this.selectedFilterSort = this.dateFilter;
    this.filterEtat = this.defaultFilterEtat;
    this.dateFilterLabel = this.getSuitableDateLabel(this.dateFilter);
  }
  get isEmptyResult() {
    return this.infiniteLoadingEnd && this.totalSpeach === 0;
  }
  getSuitableDateLabel(dateFilter) {
    switch (dateFilter) {
      case DateFilterEnum.PUBLICATION_DATE:
        return 'speachGrid.filter.publicationDate';
      default:
        return SpeachGrid_1.DEFAULT_DATE_FILTER_LABEL;
    }
  }
  onFixChange(value) {
    this.fixeBar = value;
  }
  reinitSearch() {
    this.currentPage = 0;
    this.infiniteLoadingEnd = false;
    this.listOfSpeach = [];
    this.totalSpeach = 0;
    this.gridID = new Date().getTime();
  }
  async loadPage() {
    const result = await axios.get(this.url, {
      params: {
        page: this.currentPage,
        sort: this.selectedFilterSort,
        name: this.filterName !== '' ? this.filterName : null,
        onlyState: this.filterEtat !== "NONE" ? this.filterEtat : null,
        asc: this.filterOrder === "ASC"
      }
    });
    return result.data;
  }
  getClass(id) {
    return "cardAnime" + (id + 1) % 12;
  }
  async infiniteHandler($state) {
    if (this.currentPage === 0 || this.listOfSpeach.length < this.totalSpeach) {
      this.currentPage++;
      const pageW = await this.loadPage();
      pageW.result.forEach(speach => {
        this.listOfSpeach.push(speach);
      });
      this.totalSpeach = pageW.total;
      $state.loaded();
    } else {
      $state.loaded();
      this.infiniteLoadingEnd = true;
      $state.complete();
    }
  }
  addSpeachTo(speachID) {
    this.$emit('addSpeachTo', speachID);
    this.listOfSpeach = this.listOfSpeach.filter(speach => speach.speachID !== speachID);
    this.totalSpeach--;
  }
  removeSpeachFrom(speachID) {
    this.confirmRemoveSpeachId = speachID;
    this.confirmRemoveAccess = this.isViewerOfThisSpeach;
  }
  get isViewerOfThisSpeach() {
    const currentSpeach = this.listOfSpeach.filter(speach => speach.speachID === this.confirmRemoveSpeachId)[0];
    return currentSpeach.canView;
  }
  confirmRemoveSpeachFrom() {
    this.$emit('removeSpeachFrom', this.confirmRemoveSpeachId);
    this.listOfSpeach = this.listOfSpeach.filter(speach => speach.speachID !== this.confirmRemoveSpeachId);
    this.totalSpeach--;
    this.confirmRemoveSpeachId = '';
  }
  changeSpeachesOrder() {
    console.log(this.listOfSpeach);
    this.$emit('changeSpeachesOrder', this.listOfSpeach);
  }
}, _defineProperty(_SpeachGrid, "DEFAULT_DATE_FILTER_LABEL", 'speachGrid.filter.date'), _SpeachGrid);
__decorate([Prop({
  required: true
})], SpeachGrid.prototype, "url", void 0);
__decorate([Prop({
  default: true
})], SpeachGrid.prototype, "filter", void 0);
__decorate([Prop({
  default: false
})], SpeachGrid.prototype, "forceInline", void 0);
__decorate([Prop({
  default: 0
})], SpeachGrid.prototype, "lineSpace", void 0);
__decorate([Prop({
  default: true
})], SpeachGrid.prototype, "emptyState", void 0);
__decorate([Prop({
  default: true
})], SpeachGrid.prototype, "publishFilter", void 0);
__decorate([Prop({
  default: true
})], SpeachGrid.prototype, "changeOrderFilter", void 0);
__decorate([Prop({
  default: 'NONE'
})], SpeachGrid.prototype, "defaultFilterEtat", void 0);
__decorate([Prop({
  default: false
})], SpeachGrid.prototype, "addCard", void 0);
__decorate([Prop({
  default: ''
})], SpeachGrid.prototype, "urlAddCard", void 0);
__decorate([Prop({
  default: ''
})], SpeachGrid.prototype, "labelAddCard", void 0);
__decorate([Prop({
  default: 'plus'
})], SpeachGrid.prototype, "addCardIcon", void 0);
__decorate([Prop({
  default: false
})], SpeachGrid.prototype, "loadRegulated", void 0);
__decorate([Prop({
  default: true
})], SpeachGrid.prototype, "commentsEnabled", void 0);
__decorate([Prop({
  default: false
})], SpeachGrid.prototype, "addButton", void 0);
__decorate([Prop({
  default: false
})], SpeachGrid.prototype, "removeButton", void 0);
__decorate([Prop({
  default: false
})], SpeachGrid.prototype, "filterByName", void 0);
__decorate([Prop({
  default: false
})], SpeachGrid.prototype, "draggable", void 0);
__decorate([Prop({
  default: DateFilterEnum.LAST_MAJ
})], SpeachGrid.prototype, "dateFilter", void 0);
__decorate([Prop({
  default: true
})], SpeachGrid.prototype, "isOwnerOfTheList", void 0);
SpeachGrid = SpeachGrid_1 = __decorate([Component({
  components: {
    AddCard,
    Icon,
    StandardSpeachCard,
    Loader,
    InfiniteLoading,
    draggable
  },
  emits: ['addSpeachTo', 'removeSpeachFrom', 'changeSpeachesOrder']
})], SpeachGrid);
export default SpeachGrid;