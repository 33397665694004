import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0e2927ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "aiStepContainer"
};
const _hoisted_2 = {
  class: "aiContent"
};
const _hoisted_3 = {
  class: "labelSubTitle"
};
const _hoisted_4 = {
  class: "contentTitle"
};
const _hoisted_5 = {
  class: "labelSubTitle"
};
const _hoisted_6 = {
  class: "contentDescription"
};
const _hoisted_7 = {
  class: "labelSubTitle"
};
const _hoisted_8 = {
  class: "planCollapserContainer"
};
const _hoisted_9 = {
  class: "chapterTitle"
};
const _hoisted_10 = {
  class: "chapterNumber"
};
const _hoisted_11 = {
  class: "chapterTitleText"
};
const _hoisted_12 = {
  class: "collapseHeaderContainer"
};
const _hoisted_13 = {
  class: "subChapterTitle"
};
const _hoisted_14 = {
  class: "subChapterNumber"
};
const _hoisted_15 = {
  class: "subChapterTitleText"
};
const _hoisted_16 = {
  class: "baseOnDocumentContainer"
};
const _hoisted_17 = {
  class: "buttonGenerateContentLine"
};
const _hoisted_18 = {
  key: 0,
  class: "successElement"
};
const _hoisted_19 = {
  key: 0,
  class: "subChapterGenerationDetail"
};
const _hoisted_20 = ["src"];
const _hoisted_21 = {
  class: "bottomLineButton"
};
const _hoisted_22 = {
  class: "lineFlexButton"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loader = _resolveComponent("Loader");
  const _component_icon = _resolveComponent("icon");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");
  const _component_el_carousel = _resolveComponent("el-carousel");
  const _component_el_collapse_item = _resolveComponent("el-collapse-item");
  const _component_el_collapse = _resolveComponent("el-collapse");
  const _component_AISpeachGeneratorPanelOption = _resolveComponent("AISpeachGeneratorPanelOption");
  const _component_w_dialog = _resolveComponent("w-dialog");
  const _component_AIChapterContentGenerator = _resolveComponent("AIChapterContentGenerator");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.generationInProgress ? (_openBlock(), _createBlock(_component_Loader, {
    key: 0
  })) : _createCommentVNode("", true), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(() => {
      var _ctx$speachGenerated, _ctx$speachGenerated2, _ctx$speachGenerated3;
      return [_withDirectives(_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('aiContent.title')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString((_ctx$speachGenerated = _ctx.speachGenerated) === null || _ctx$speachGenerated === void 0 ? void 0 : _ctx$speachGenerated.title), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('aiContent.description')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString((_ctx$speachGenerated2 = _ctx.speachGenerated) === null || _ctx$speachGenerated2 === void 0 ? void 0 : _ctx$speachGenerated2.description), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('aiContent.plan')), 1), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx$speachGenerated3 = _ctx.speachGenerated) === null || _ctx$speachGenerated3 === void 0 ? void 0 : _ctx$speachGenerated3.chapters, (chapter, indexChapter) => {
        return _openBlock(), _createElementBlock("div", {
          key: 'chapter' + indexChapter
        }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('aiContent.chapter')) + " " + _toDisplayString(indexChapter + 1) + ":", 1), _createElementVNode("div", _hoisted_11, _toDisplayString(chapter.title), 1)]), _createVNode(_component_el_collapse, {
          class: "withoutBorder"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chapter.subchapters, (subchapter, indexSubChapter) => {
            return _openBlock(), _createBlock(_component_el_collapse_item, {
              name: 'chapter' + indexChapter + '-' + indexSubChapter,
              key: 'subchapter' + indexSubChapter
            }, {
              title: _withCtx(() => {
                var _ctx$getOriginalFileF;
                return [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(indexChapter + 1) + "." + _toDisplayString(indexSubChapter + 1) + ":", 1), _createElementVNode("div", _hoisted_15, _toDisplayString(subchapter.title), 1)]), _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('aiContent.baseOnDocument', {
                  name: (_ctx$getOriginalFileF = _ctx.getOriginalFileForName(subchapter.documentPages[0].documentName)) === null || _ctx$getOriginalFileF === void 0 ? void 0 : _ctx$getOriginalFileF.name,
                  minPage: subchapter.documentPages[0].pageNumber,
                  maxPage: subchapter.documentPages[subchapter.documentPages.length - 1].pageNumber
                })), 1), _createElementVNode("div", _hoisted_17, [_withDirectives(_createVNode(_component_standard_button, {
                  onAction: $event => _ctx.launchChapterGeneration(indexChapter, indexSubChapter)
                }, {
                  default: _withCtx(() => [_createVNode(_component_icon, {
                    icon: "creation-outline"
                  }), _createTextVNode(" " + _toDisplayString(_ctx.$t('aiContent.generateThisChapter')), 1)]),
                  _: 2
                }, 1032, ["onAction"]), [[_vShow, _ctx.speachToMap && !_ctx.getWeetIdForChapter(indexChapter, indexSubChapter)]]), _ctx.speachToMap && _ctx.getWeetIdForChapter(indexChapter, indexSubChapter) ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_icon, {
                  icon: "check-circle-outline",
                  class: "iconCheck"
                }), _createTextVNode(" " + _toDisplayString(_ctx.$t('aiContent.contentGenerated')), 1)])) : _createCommentVNode("", true), _ctx.speachToMap && _ctx.getWeetIdForChapter(indexChapter, indexSubChapter) ? (_openBlock(), _createBlock(_component_standard_button, {
                  class: "buttonEdit",
                  key: 'buttonEdit' + _ctx.indexLecon + '-' + indexChapter,
                  outlined: true,
                  onAction: $event => _ctx.launchChapterGeneration(indexChapter, indexSubChapter)
                }, {
                  default: _withCtx(() => [_createVNode(_component_icon, {
                    icon: "pencil-outline"
                  }), _createTextVNode(" " + _toDisplayString(_ctx.$t('aiContent.editThisChapter')), 1)]),
                  _: 2
                }, 1032, ["onAction"])) : _createCommentVNode("", true)])])];
              }),
              default: _withCtx(() => [subchapter.documentPages.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(_component_el_carousel, {
                ref_for: true,
                ref: 'carrousel' + indexChapter + '-' + indexSubChapter,
                height: "200px",
                "motion-blur": false,
                autoplay: false,
                loop: false,
                arrow: 'never'
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subchapter.documentPages, page => {
                  return _openBlock(), _createBlock(_component_el_carousel_item, {
                    key: page.documentName + page.pageNumber
                  }, {
                    default: _withCtx(() => [_createElementVNode("img", {
                      src: _ctx.getPageImage(page),
                      style: {
                        "max-width": "100%",
                        "max-height": "100%"
                      }
                    }, null, 8, _hoisted_20)]),
                    _: 2
                  }, 1024);
                }), 128))]),
                _: 2
              }, 1536), subchapter.documentPages.length > 1 ? (_openBlock(), _createBlock(_component_standard_button, {
                key: 0,
                outlined: true,
                class: "buttonNext",
                onClick: $event => _ctx.nextSlide('carrousel' + indexChapter + '-' + indexSubChapter)
              }, {
                default: _withCtx(() => [_createVNode(_component_icon, {
                  icon: "arrow-right"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true), subchapter.documentPages.length > 1 ? (_openBlock(), _createBlock(_component_standard_button, {
                key: 1,
                outlined: true,
                class: "buttonPrev",
                onClick: $event => _ctx.prevSlide('carrousel' + indexChapter + '-' + indexSubChapter)
              }, {
                default: _withCtx(() => [_createVNode(_component_icon, {
                  icon: "arrow-left"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]),
              _: 2
            }, 1032, ["name"]);
          }), 128))]),
          _: 2
        }, 1024)]);
      }), 128))]), _createElementVNode("div", _hoisted_21, [!_ctx.speachToMap ? (_openBlock(), _createBlock(_component_standard_button, {
        key: 0,
        outlined: true,
        class: "buttonRefresh",
        onAction: _cache[0] || (_cache[0] = $event => _ctx.showOptionPanel = true)
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "refresh",
          class: "iconButtonElement"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('aiContent.regenerate')), 1)]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_standard_button, {
        onAction: _cache[1] || (_cache[1] = $event => _ctx.validateAiSpeach(true)),
        outlined: true,
        class: "buttonRefresh"
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "content-save-edit-outline",
          class: "iconButtonElement"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('aiContent.saveAndEdit')), 1)]),
        _: 1
      }), !_ctx.speachToMap ? (_openBlock(), _createBlock(_component_standard_button, {
        key: 1,
        onAction: _cache[2] || (_cache[2] = $event => _ctx.validateAiSpeach(false))
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "creation-outline",
          class: "iconButtonElement"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('aiContent.generateContent')), 1)]),
        _: 1
      })) : _createCommentVNode("", true)]), _createVNode(_component_w_dialog, {
        visible: _ctx.showOptionPanel,
        onOnDialogClose: _cache[4] || (_cache[4] = $event => _ctx.showOptionPanel = false)
      }, {
        default: _withCtx(() => [_createVNode(_component_AISpeachGeneratorPanelOption, {
          class: "panelOption",
          "specific-prompt": _ctx.specificPromptSelected,
          language: _ctx.languageSelected,
          "allow-reorder": _ctx.allowReorderSelected,
          duration: _ctx.durationSelected,
          onDurationChange: _ctx.onDurationChange,
          onAllowReorder: _ctx.onAllowReorder,
          onLanguageChange: _ctx.onLanguageChange,
          onSpecificPrompt: _ctx.onSpecificPromptChange
        }, null, 8, ["specific-prompt", "language", "allow-reorder", "duration", "onDurationChange", "onAllowReorder", "onLanguageChange", "onSpecificPrompt"]), _createElementVNode("div", _hoisted_22, [_createVNode(_component_standard_button, {
          outlined: true,
          class: "buttonRefresh",
          onAction: _cache[3] || (_cache[3] = $event => _ctx.showOptionPanel = false)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('aiContent.cancel')), 1)]),
          _: 1
        }), _createVNode(_component_standard_button, {
          outlined: false,
          class: "buttonRefresh",
          onAction: _ctx.regenerate
        }, {
          default: _withCtx(() => [_createVNode(_component_icon, {
            icon: "refresh",
            class: "iconButtonElement"
          }), _createTextVNode(" " + _toDisplayString(_ctx.$t('aiContent.regenerate')), 1)]),
          _: 1
        }, 8, ["onAction"])])]),
        _: 1
      }, 8, ["visible"]), _ctx.indexChapter > -1 ? (_openBlock(), _createBlock(_component_AIChapterContentGenerator, {
        key: 0,
        "weet-endpoint": _ctx.weetEndpoint,
        "speach-generated": _ctx.speachGenerated,
        "speach-to-map": _ctx.speachToMap,
        aiFiles: _ctx.aiFiles,
        language: _ctx.language,
        "index-chapter": _ctx.indexChapter,
        "index-lecon": _ctx.indexLecon,
        onOnClose: _ctx.onCloseContentGeneration
      }, null, 8, ["weet-endpoint", "speach-generated", "speach-to-map", "aiFiles", "language", "index-chapter", "index-lecon", "onOnClose"])) : _createCommentVNode("", true)], 512), [[_vShow, !_ctx.generationInProgress]])];
    }),
    _: 1
  })]);
}