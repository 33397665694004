import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import GridLayout from "@/components/layout/GridLayout.vue";
import { isAdmin, isApprover, isAuthor } from "@/util/rolesUtils";
import SpeachGrid from "@/components/speachGrid/SpeachGrid.vue";
import { toRecorderSpeach } from "@/util/routeUtil";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let MyAccount = class MyAccount extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "workflowEnabled", void 0);
    _defineProperty(this, "delayEnabled", void 0);
    _defineProperty(this, "speachRefused", void 0);
    _defineProperty(this, "speachToValidate", void 0);
    _defineProperty(this, "speachToReview", void 0);
    _defineProperty(this, "selected", void 0);
    _defineProperty(this, "urlToChange", void 0);
    _defineProperty(this, "commentsEnabled", void 0);
    _defineProperty(this, "selectedTab", 'EDITED');
  }
  mounted() {
    this.selectedTab = this.selected;
  }
  updateUrl() {
    window.history.pushState(null, document.title, this.urlToChange + this.selectedTab);
  }
  get isAuthor() {
    return isAuthor();
  }
  get isApprover() {
    return isApprover();
  }
  get isAdmin() {
    return isAdmin();
  }
  get urlSpeachRecorder() {
    return toRecorderSpeach();
  }
  get isDelayEnabled() {
    return this.delayEnabled === 'true';
  }
  get isWorkflowEnabled() {
    return this.workflowEnabled === 'true';
  }
};
__decorate([Prop({
  required: true
})], MyAccount.prototype, "workflowEnabled", void 0);
__decorate([Prop({
  required: true
})], MyAccount.prototype, "delayEnabled", void 0);
__decorate([Prop({
  default: 0
})], MyAccount.prototype, "speachRefused", void 0);
__decorate([Prop({
  default: 0
})], MyAccount.prototype, "speachToValidate", void 0);
__decorate([Prop({
  default: 0
})], MyAccount.prototype, "speachToReview", void 0);
__decorate([Prop({
  default: 'EDITED'
})], MyAccount.prototype, "selected", void 0);
__decorate([Prop({
  default: '/account?tabSelected='
})], MyAccount.prototype, "urlToChange", void 0);
__decorate([Prop({
  required: true
})], MyAccount.prototype, "commentsEnabled", void 0);
__decorate([Watch("selectedTab", {
  deep: false,
  immediate: false
})], MyAccount.prototype, "updateUrl", null);
MyAccount = __decorate([Component({
  components: {
    SpeachGrid,
    GridLayout
  }
})], MyAccount);
export default MyAccount;