import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7cbb0722"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "result"
};
const _hoisted_2 = {
  class: "subline"
};
const _hoisted_3 = {
  class: "text"
};
const _hoisted_4 = {
  class: "total"
};
const _hoisted_5 = {
  key: 0,
  class: "sub-total"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_icon, {
    color: "light2",
    icon: _ctx.icon,
    size: "large"
  }, null, 8, ["icon"])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1), _createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.total) + " ", 1), _ctx.subTotal ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (" + _toDisplayString(`${_ctx.subTotal} ${_ctx.$t('admin.dashboard.aiEditor')}`) + ") ", 1)) : _createCommentVNode("", true)])])]);
}