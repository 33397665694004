import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import axios from "axios";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { Events } from "@/events/events";
import WCheckbox from "@/components/form/w-checkbox.vue";
import WSelect from "@/components/form/w-select.vue";
let ShareChapterWithSelector = class ShareChapterWithSelector extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speachId", void 0);
    _defineProperty(this, "selectorId", void 0);
    _defineProperty(this, "chapters", []);
    _defineProperty(this, "selectChapter", false);
    _defineProperty(this, "selectedChapter", "");
  }
  mounted() {
    this.getChapters();
  }
  // retrieve all the chapters of the speach
  getChapters() {
    axios.get("/api/v2/cours/" + this.speachId + "/publishDetails").then(response => {
      this.extractChapters(response.data);
    });
  }
  resetChooseChapter() {
    this.selectedChapter = "";
    trig(Events.RESET_SHARABLE_SPEACH_LINKS, {
      selectorId: this.selectorId
    });
  }
  updateLinksWithChapters() {
    trig(Events.UPDATE_SHARABLE_SPEACH_LINKS, {
      chapterID: this.selectedChapter,
      selectorId: this.selectorId
    });
  }
  extractChapters(speachDetails) {
    for (const lecon of speachDetails.lecons) {
      for (const chapter of lecon.chapitres) {
        this.chapters.push(chapter);
      }
    }
  }
};
__decorate([Prop({
  required: true
})], ShareChapterWithSelector.prototype, "speachId", void 0);
__decorate([Prop({
  required: true
})], ShareChapterWithSelector.prototype, "selectorId", void 0);
ShareChapterWithSelector = __decorate([Component({
  components: {
    WSelect,
    WCheckbox
  }
})], ShareChapterWithSelector);
export default ShareChapterWithSelector;