import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import { __decorate } from "tslib";
import axios from "axios";
import { debounce } from "lodash";
import Loader from "@/components/loader/Loader.vue";
import UserCell from "@/components/user/UserCell.vue";
import WImage from "@/components/wrappers/w-image.vue";
import WTag from "@/components/wrappers/w-tag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import SidePanel from "@/components/layout/SidePanel.vue";
import UserDetails from "@/components/user/UserDetails.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import WDatePickerRange from "@/components/form/w-date-picker-range.vue";
import WInput from "@/components/form/w-input.vue";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import WDate from "@/components/wrappers/w-date.vue";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import InfiniteLoading from "vue-infinite-loading";
import dayjs from "dayjs";
import { Component, Prop, Vue, Ref } from "vue-facing-decorator";
import BatchUpdateUser from "@/components/user/BatchUpdateUser.vue";
let AdminListUsers = class AdminListUsers extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "defaultFilterEtat", void 0);
    _defineProperty(this, "emptyState", void 0);
    _defineProperty(this, "filterName", void 0);
    _defineProperty(this, "preSelectedGroups", void 0);
    _defineProperty(this, "workflowEnabled", void 0);
    _defineProperty(this, "userPasswordIsEditable", false);
    _defineProperty(this, "userPasswordMinChars", void 0);
    _defineProperty(this, "userPasswordMaxChars", void 0);
    _defineProperty(this, "selectedRole", void 0);
    _defineProperty(this, "aiLicenseAvailable", void 0);
    _defineProperty(this, "tableRef", void 0);
    // noinspection JSMismatchedCollectionQueryUpdate: the listUsers parameter is used as data for <el-table> line 44
    _defineProperty(this, "listUsers", []);
    _defineProperty(this, "currentPage", 1);
    _defineProperty(this, "totalUsers", 0);
    _defineProperty(this, "filterSort", null);
    _defineProperty(this, "filterByName", "");
    _defineProperty(this, "filterEtat", "");
    _defineProperty(this, "filterOrder", "DESC");
    _defineProperty(this, "fixeBar", false);
    _defineProperty(this, "infiniteLoadingEnd", false);
    _defineProperty(this, "busyInfiniteLoading", false);
    _defineProperty(this, "gridID", new Date().getTime());
    // noinspection JSMismatchedCollectionQueryUpdate: the groups parameter is used as options for <w-select> line 18
    _defineProperty(this, "groups", []);
    _defineProperty(this, "selectedGroups", []);
    // noinspection JSMismatchedCollectionQueryUpdate: the roles parameter is used as options for <w-select> line 27
    _defineProperty(this, "roles", []);
    _defineProperty(this, "selectedRoles", []);
    _defineProperty(this, "calendarRangeCreated", null);
    _defineProperty(this, "calendarRangeLastActive", null);
    _defineProperty(this, "sidePanelVisible", false);
    _defineProperty(this, "selectedUser", null);
    _defineProperty(this, "multiselectUsers", []);
    _defineProperty(this, "deactivateUsersPopup", false);
    _defineProperty(this, "editInfosPopup", false);
    _defineProperty(this, "filterExpanded", false);
    _defineProperty(this, "BASE_API_USERS", '/api/v2/admin/users');
    // boolean to indicate the first page of user is loaded so you can show the table
    _defineProperty(this, "isLoaded", false);
    _defineProperty(this, "debouncedSearch", debounce(this.reinitSearch, 500));
    _defineProperty(this, "debounceInfinite", debounce($state => {
      this.infiniteHandler($state);
    }, 250));
  }
  mounted() {
    this.filterByName = this.filterName;
    this.filterEtat = this.defaultFilterEtat;
    this.initRoles();
    this.initGroups();
    this.reinitSearch();
  }
  initGroups() {
    // convert the string in array because vue component from jsquery receive only string
    this.selectedGroups = JSON.parse(this.preSelectedGroups);
    axios.get(this.BASE_API_USERS + '/groups').then(response => {
      this.groups = response.data.map(group => {
        if (group.system) {
          group.name = this.$t('admin.dashboard.users.search.filter.noGroups');
        }
        return group;
      }).sort((a, b) => {
        if (a.system) {
          return -1;
        } else {
          if (b.system) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    });
  }
  groupName(groupId) {
    var _this$groups$find;
    return ((_this$groups$find = this.groups.find(group => group.id === groupId)) === null || _this$groups$find === void 0 ? void 0 : _this$groups$find.name) || 'undefined group';
  }
  initRoles() {
    this.roles = [{
      id: 'NONE',
      name: this.$t('admin.dashboard.users.search.filter.noRoles')
    }, {
      id: 'ADMIN',
      name: this.$t('admin.dashboard.users.role.administrator')
    }, {
      id: 'FORMATEUR',
      name: this.$t('admin.dashboard.users.role.author')
    }, {
      id: 'AI_EDITOR',
      name: this.$t('admin.dashboard.users.role.aiEditor')
    }];
    if (this.workflowEnabled) {
      this.roles.push({
        id: 'APPROVER',
        name: this.$t('admin.dashboard.users.role.approver')
      });
    }
    //add role from url
    if (this.selectedRole) {
      const roleToAdd = this.roles.find(role => role.id == this.selectedRole);
      if (roleToAdd) {
        this.selectedRoles.push(roleToAdd.id);
      }
    }
  }
  roleName(roleId) {
    var _this$roles$find;
    return ((_this$roles$find = this.roles.find(role => role.id === roleId)) === null || _this$roles$find === void 0 ? void 0 : _this$roles$find.name) || 'undefined role';
  }
  getRolesForUser(user) {
    const roles = [];
    if (user.admin) {
      roles.push({
        id: 'ADMIN',
        name: this.$t('admin.dashboard.users.role.administrator')
      });
    }
    if (user.trainer) {
      roles.push({
        id: 'FORMATEUR',
        name: this.$t('admin.dashboard.users.role.author')
      });
    }
    if (user.aiEditor) {
      roles.push({
        id: 'AI_EDITOR',
        name: this.$t('admin.dashboard.users.role.aiEditor')
      });
    }
    if (this.workflowEnabled && user.approver) {
      roles.push({
        id: 'APPROVER',
        name: this.$t('admin.dashboard.users.role.approver')
      });
    }
    return roles;
  }
  get selectedLastActive() {
    if (this.calendarRangeLastActive && this.calendarRangeLastActive[0] && this.calendarRangeLastActive[1]) {
      return this.$d(this.calendarRangeLastActive[0]) + ' - ' + this.$d(this.calendarRangeLastActive[1]);
    }
    return '';
  }
  get selectedCreated() {
    if (this.calendarRangeCreated && this.calendarRangeCreated[0] && this.calendarRangeCreated[1]) {
      return this.$d(this.calendarRangeCreated[0]) + ' - ' + this.$d(this.calendarRangeCreated[1]);
    }
    return '';
  }
  get isEmptyResult() {
    return this.infiniteLoadingEnd && this.totalUsers === 0;
  }
  onFixChange(value) {
    this.fixeBar = value;
  }
  reinitSearch() {
    this.currentPage = 1;
    this.infiniteLoadingEnd = false;
    this.listUsers = [];
    this.gridID = new Date().getTime();
    this.multiselectUsers = [];
    // load the first page
    this.getListUsers().then(pageW => {
      this.listUsers.push(...pageW.result);
      this.totalUsers = pageW.total;
      this.isLoaded = true;
    });
  }
  changeSelectedGroups(newGroups) {
    if (!this.isLoaded) {
      return;
    }
    this.selectedGroups = newGroups;
    this.reinitSearch();
  }
  changeSelectedRoles(newRoles) {
    if (!this.isLoaded) {
      return;
    }
    this.selectedRoles = newRoles;
    this.reinitSearch();
  }
  changeCalendarRangeCreated(newCalendarRange) {
    if (!this.isLoaded) {
      return;
    }
    this.calendarRangeCreated = newCalendarRange;
    this.reinitSearch();
  }
  changeCalendarRangeLastActive(newCalendarRange) {
    if (!this.isLoaded) {
      return;
    }
    this.calendarRangeLastActive = newCalendarRange;
    this.reinitSearch();
  }
  clearFilterByName() {
    this.filterByName = '';
    this.reinitSearch();
  }
  clearFilterByGroup(group) {
    this.selectedGroups = this.selectedGroups.filter(selectedGroup => selectedGroup !== group);
    this.reinitSearch();
  }
  clearFilterByRole(role) {
    this.selectedRoles = this.selectedRoles.filter(selectedRole => selectedRole !== role);
    this.reinitSearch();
  }
  clearFilterByLastActive() {
    this.calendarRangeLastActive = null;
    this.reinitSearch();
  }
  clearFilterByCreated() {
    this.calendarRangeCreated = null;
    this.reinitSearch();
  }
  clearAllFilters() {
    this.filterByName = '';
    this.selectedGroups = [];
    this.selectedRoles = [];
    this.calendarRangeCreated = null;
    this.calendarRangeLastActive = null;
    this.reinitSearch();
  }
  async getListUsers() {
    const dateFormat = 'YYYY-MM-DD';
    const result = await axios.get(this.BASE_API_USERS, {
      params: {
        page: this.currentPage,
        sort: this.filterSort,
        search: this.filterByName !== '' ? this.filterByName : null,
        groups: this.selectedGroups,
        roles: this.selectedRoles,
        startDateCreated: this.calendarRangeCreated ? dayjs(this.calendarRangeCreated[0]).format(dateFormat) : null,
        endDateCreated: this.calendarRangeCreated ? dayjs(this.calendarRangeCreated[1]).format(dateFormat) : null,
        startDateLastActive: this.calendarRangeLastActive ? dayjs(this.calendarRangeLastActive[0]).format(dateFormat) : null,
        endDateLastActive: this.calendarRangeLastActive ? dayjs(this.calendarRangeLastActive[1]).format(dateFormat) : null,
        onlyState: this.filterEtat !== "NONE" ? this.filterEtat : null,
        asc: this.filterOrder === "ASC"
      }
    });
    return result.data;
  }
  infiniteHandler($state) {
    // fix an issue with eltable, when scroll down, that trig infinite scroll all time
    const page = document.getElementById('page');
    page.scrollTop = page.scrollTop - 100;
    // end of ugly fix
    this.busyInfiniteLoading = true;
    this.infiniteLoadingEnd = false;
    this.currentPage++;
    this.getListUsers().then(pageW => {
      if (pageW.result.length > 0) {
        this.listUsers.push(...pageW.result);
        this.totalUsers = pageW.total;
        $state.loaded();
      } else {
        if (pageW.total === 0) {
          this.listUsers = [];
          this.totalUsers = 0;
        }
        this.infiniteLoadingEnd = true;
        $state.complete();
      }
      this.busyInfiniteLoading = false;
      this.isLoaded = true;
    });
  }
  selectUser(user) {
    this.selectedUser = user;
    this.sidePanelVisible = true;
  }
  handleUsersSelectionChange(users) {
    this.multiselectUsers = users;
  }
  deselectAllUser() {
    this.multiselectUsers = [];
    this.tableRef.clearSelection();
  }
  deactivateListUsers() {
    axios.put(this.BASE_API_USERS + '/deactivate', {
      uuids: this.multiselectUsers.map(user => user.userID)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.users.sidebar.userDeactivated"));
    }, error => {
      WElMessage.showErrorMessage(error);
    }).finally(() => {
      this.multiselectUsers = [];
      this.deactivateUsersPopup = false;
      this.reinitSearch();
    });
  }
  batchEditUsers() {
    this.editInfosPopup = false;
    this.multiselectUsers = [];
    this.reinitSearch();
  }
  closeBatchEdit() {
    this.editInfosPopup = false;
  }
  closeSidePanel() {
    this.sidePanelVisible = false;
  }
  changeFilterExpandedState() {
    this.filterExpanded = !this.filterExpanded;
  }
};
__decorate([Prop({
  default: 'NONE'
})], AdminListUsers.prototype, "defaultFilterEtat", void 0);
__decorate([Prop({
  default: true
})], AdminListUsers.prototype, "emptyState", void 0);
__decorate([Prop({
  default: ''
})], AdminListUsers.prototype, "filterName", void 0);
__decorate([Prop({
  default: ''
})], AdminListUsers.prototype, "preSelectedGroups", void 0);
__decorate([Prop({
  default: false
})], AdminListUsers.prototype, "workflowEnabled", void 0);
__decorate([Prop()], AdminListUsers.prototype, "userPasswordIsEditable", void 0);
__decorate([Prop({
  required: true
})], AdminListUsers.prototype, "userPasswordMinChars", void 0);
__decorate([Prop({
  required: true
})], AdminListUsers.prototype, "userPasswordMaxChars", void 0);
__decorate([Prop({
  default: ''
})], AdminListUsers.prototype, "selectedRole", void 0);
__decorate([Prop({
  default: false
})], AdminListUsers.prototype, "aiLicenseAvailable", void 0);
__decorate([Ref()], AdminListUsers.prototype, "tableRef", void 0);
AdminListUsers = __decorate([Component({
  components: {
    BatchUpdateUser,
    WDate,
    WDialog,
    WInput,
    WDatePickerRange,
    WToolTip,
    Icon,
    StandardButton,
    UserDetails,
    IconButton,
    WSelectAdvanced,
    WTag,
    WImage,
    UserCell,
    Loader,
    InfiniteLoading,
    SidePanel
  }
})], AdminListUsers);
export default AdminListUsers;