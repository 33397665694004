import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import GridLayout from "@/components/layout/GridLayout.vue";
import SidePanel from "@/components/layout/SidePanel.vue";
import NotificationParameters from "@/components/notifications/NotificationParameters.vue";
import NotificationList from "@/components/notifications/subcomponent/NotificationList.vue";
import WSelect from "@/components/form/w-select.vue";
import NotificationIcon from "@/components/notifications/subcomponent/NotificationIcon.vue";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import Loader from "@/components/loader/Loader.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import { WeetEventName } from "@/weet/weetEvent";
import IconButton from "@/components/buttons/IconButton.vue";
import { Events } from "@/events/events";
import { alertError } from "@/util/dialog";
import { getUrlPlayer, getWeetEditingUrl, refresh } from "@/util/routeUtil";
import StandardButton from "@/components/buttons/StandardButton.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import delay from "delay";
import { onGenericWeetEditingMessage, updateWeetRecorded } from "@/util/weetMessageUtil";
let WeetChapter = class WeetChapter extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "weetAccessToken", void 0);
    //access token to use weet
    _defineProperty(this, "chapterJson", void 0);
    _defineProperty(this, "weetEndpoint", void 0);
    _defineProperty(this, "boutiqueId", void 0);
    _defineProperty(this, "transcriptEnabled", void 0);
    _defineProperty(this, "oldUIAvailable", void 0);
    _defineProperty(this, "loadingInterface", false);
    _defineProperty(this, "transcriptEnabledBoolean", false);
    _defineProperty(this, "editMode", false);
    _defineProperty(this, "playerMode", true);
    _defineProperty(this, "saving", false);
    _defineProperty(this, "returnToJquery", false);
    _defineProperty(this, "mode", "NONE");
    _defineProperty(this, "oldUIAvailableBoolean", false);
    _defineProperty(this, "desactivateLegacyLink", false);
    _defineProperty(this, "chapter", void 0);
    _defineProperty(this, "weetID", "");
  }
  get urlEditing() {
    return getWeetEditingUrl(this.weetEndpoint, this.weetAccessToken, this.boutiqueId, this.weetID, this.mode, this.chapter.title);
  }
  get urlPlayer() {
    return getUrlPlayer(this.weetEndpoint, this.weetAccessToken, this.boutiqueId, this.weetID);
  }
  get urlIframe() {
    if (this.weetID) {
      return this.urlPlayer;
    } else {
      return this.urlEditing;
    }
  }
  iFrameLoaded() {
    this.loadingInterface = false;
  }
  mounted() {
    this.loadingInterface = true;
    this.chapter = JSON.parse(this.chapterJson);
    this.oldUIAvailableBoolean = this.oldUIAvailable === "true";
    this.transcriptEnabledBoolean = this.transcriptEnabled === "true";
    this.weetID = this.chapter.videoID;
    if (!this.weetID) {
      this.switchEditMode();
    } else {
      this.editMode = false;
      this.playerMode = true;
    }
    if (!this.chapter) {
      console.error("Impossible to access weet without chapter");
      return;
    }
    if (!this.weetAccessToken) {
      console.error("Impossible to access weet without access token");
    }
  }
  stopEditing(withoutDelay = false) {
    this.editMode = false;
    window.removeEventListener("message", this.onMessageReceive);
    if (withoutDelay) {
      this.playerMode = true;
    } else {
      // activate player after delay to avoid chrome crash
      delay(100).then(() => {
        this.playerMode = true;
      });
    }
  }
  switchEditMode() {
    this.stopEditing(true); // be sure
    this.mode = "NONE";
    this.editMode = true;
    this.playerMode = false;
    this.loadingInterface = true;
    window.addEventListener('message', this.onMessageReceive);
  }
  cutMode() {
    this.switchEditMode();
    this.mode = "CUT";
  }
  interactionMode() {
    this.switchEditMode();
    this.mode = "INTERACTION";
  }
  transcriptMode() {
    this.switchEditMode();
    this.mode = "SUBTITLE";
  }
  voiceOverMode() {
    this.switchEditMode();
    this.mode = "VOICE_OVER";
  }
  downloadMode() {
    this.switchEditMode();
    this.mode = "DOWNLOAD";
  }
  goToOldInterface() {
    trig(Events.SELECT_OLD_VIDEO_UI_CHAPTER, {
      chapterId: this.chapter.id
    });
    this.returnToJquery = true;
  }
  openSuccessDiscover() {
    open("https://success.speach.me/s/b16cf4adc01/product-release-oct-2024-new-video-recorder", "speachSuccessTarget");
  }
  async onMessageReceive(event) {
    // manage genrique message of weet
    const objData = onGenericWeetEditingMessage(event, this.weetEndpoint, this.chapter.id);
    if (objData) {
      // Update weet id silently to keep context if refresh browser
      if (objData.name === WeetEventName.SILENTLY_SEND_WEET_ID) {
        this.desactivateLegacyLink = true;
      }
      // End Edition Weet
      if (objData.name === WeetEventName.SEND_WEET_ID) {
        this.weetID = objData.data.weetID;
        this.stopEditing();
        this.saving = true;
        if (objData.data.modified) {
          try {
            const weetRepresentation = await updateWeetRecorded(this.weetID, this.chapter.id, objData.data.duration);
            trig(Events.RAFRAICHIR_PLAN_CHAPITRE, this.chapter.id);
            this.weetID = weetRepresentation.weetID;
            this.saving = false;
          } catch (err) {
            console.error("error on save weet", err);
            this.saving = false;
            alertError(err.toString());
            setTimeout(() => {
              refresh();
            }, 4000);
          }
        } else {
          // Weet not modified so we just refresh the chapter to stop the edition
          this.weetID = objData.data.weetID;
          this.saving = false;
          if (!this.weetID) {
            trig(Events.AFFICHER_CHOIX_TYPE_CHAPITRE, this.chapter.id);
            return;
          }
        }
      }
    }
  }
};
__decorate([Prop({
  required: true
})], WeetChapter.prototype, "weetAccessToken", void 0);
__decorate([Prop({
  required: true
})], WeetChapter.prototype, "chapterJson", void 0);
__decorate([Prop({
  required: true
})], WeetChapter.prototype, "weetEndpoint", void 0);
__decorate([Prop({
  required: true
})], WeetChapter.prototype, "boutiqueId", void 0);
__decorate([Prop({
  required: true
})], WeetChapter.prototype, "transcriptEnabled", void 0);
__decorate([Prop({
  default: "false"
})], WeetChapter.prototype, "oldUIAvailable", void 0);
WeetChapter = __decorate([Component({
  components: {
    StandardButton,
    IconButton,
    WDialog,
    Loader,
    WToolTip,
    Icon,
    NotificationIcon,
    WSelect,
    NotificationList,
    NotificationParameters,
    SidePanel,
    GridLayout
  }
})], WeetChapter);
export default WeetChapter;