import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.error.cause.js";
import { __decorate } from "tslib";
import axios from "axios";
import WToggle from "@/components/form/w-toggle.vue";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import WDatePicker from "@/components/form/w-date-picker.vue";
import dayjs from "dayjs";
import { BadgeType } from "@/models/badge.model";
import { Events } from "@/events/events";
import Icon from "@/components/icon/Icon.vue";
import delay from "delay";
import { Component, Prop, Vue } from "vue-facing-decorator";
let DueDateSettings = class DueDateSettings extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speachId", void 0);
    _defineProperty(this, "badgeType", void 0);
    _defineProperty(this, "badgeSet", false);
    _defineProperty(this, "speach", void 0);
    _defineProperty(this, "loaded", false);
    _defineProperty(this, "dueDateSet", null);
    _defineProperty(this, "isDueDateInThePast", false);
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/speach');
  }
  mounted() {
    this.getSpeachWithMetadata().then(() => {
      this.badgeSet = this.badgeType !== BadgeType.NONE;
      register(Events.BADGE_SET, () => this.changeBadgeSet(true));
      register(Events.BADGE_UNSET, () => this.changeBadgeSet(false));
      this.loaded = true;
    });
  }
  beforeDestroy() {
    unregister(Events.BADGE_SET);
    unregister(Events.BADGE_UNSET);
  }
  changeBadgeSet(badgeSet) {
    this.badgeSet = badgeSet;
  }
  async getSpeachWithMetadata() {
    return await axios.get(this.BASE_API_SPEACHES + '/speachWithMetadata', {
      params: {
        speachID: this.speachId
      }
    }).then(response => {
      this.speach = response.data;
      // We need to format date we receive from back-end before display
      this.dueDateSet = this.buildDateForDisplay(this.speach.speachMetadata.dueDate);
      this.isDueDateInThePast = this.speach.speachMetadata.dueDateTimestamp <= new Date().getTime();
    });
  }
  /**
   * Build date to render inside date picker
   * @param dateString
   * @private
   */
  buildDateForDisplay(dateString) {
    if (!dateString) {
      return null;
    }
    let dayJsDate = dayjs(dateString, ['DD/MM/YYYY', 'MM-DD-YYYY'], true); // supported formats is Speach API
    if (dayJsDate.isValid()) {
      return dayJsDate.toDate();
    } else {
      throw new Error(`${dateString} has invalid format.`);
    }
  }
  async changeDueDate(newValue) {
    const dateFormat = 'YYYY-MM-DD';
    const today = dayjs().toDate();
    today.setHours(0, 0, 0, 0);
    let formattedDueDate = null;
    let timestampDueDate = 0;
    let timestampTomorrow = 0;
    if (newValue !== null) {
      formattedDueDate = dayjs(newValue).format(dateFormat);
      timestampDueDate = newValue.setHours(0, 0, 0, 0);
      this.dueDateSet = new Date(timestampDueDate);
      // Increasing the day of the month for today, automatically taking care of the next month/year switch if needed
      timestampTomorrow = today.setDate(today.getDate() + 1);
    }
    if (timestampDueDate < timestampTomorrow) {
      // if due date is in the past, we don't send a request to the back and display an error
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdateFailed"));
      await delay(200);
      this.dueDateSet = new Date(this.speach.speachMetadata.dueDateTimestamp);
    } else {
      await axios.put(this.BASE_API_SPEACHES + '/setDueDate', {
        speachID: this.speachId,
        dueDate: formattedDueDate
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(speachMetadataResponse => {
        const speachMetadata = speachMetadataResponse.data;
        this.speach.speachMetadata.dueDateTimestamp = speachMetadata.dueDateTimestamp;
        this.speach.speachMetadata.unpublishAfterDueDate = speachMetadata.unpublishAfterDueDate;
        this.isDueDateInThePast = this.speach.speachMetadata.dueDateTimestamp <= new Date().getTime();
        if (this.isDueDateInThePast) {
          trig(Events.DUE_DATE_EXPIRED_SET);
        } else {
          trig(Events.DUE_DATE_EXPIRED_UNSET);
        }
        WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
      }, () => {
        WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdateFailed"));
      });
    }
  }
  async changeUnpublishAfterDueDate(value) {
    await axios.put(this.BASE_API_SPEACHES + '/setUnpublishedAfterDueDate', {
      speachID: this.speachId,
      unpublish: `${value}`
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      this.speach.speachMetadata.unpublishAfterDueDate = value;
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdateFailed"));
    });
  }
};
__decorate([Prop({
  required: true
})], DueDateSettings.prototype, "speachId", void 0);
__decorate([Prop({
  default: 'NONE'
})], DueDateSettings.prototype, "badgeType", void 0);
DueDateSettings = __decorate([Component({
  components: {
    Icon,
    WDatePicker,
    WToggle
  }
})], DueDateSettings);
export default DueDateSettings;