import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import BigButton from "@/components/buttons/BigButton.vue";
import WInput from "@/components/form/w-input.vue";
import AIStepsGenerator from "@/components/aiSpeachGenerator/AIStepsGenerator.vue";
import WSelect from "@/components/form/w-select.vue";
import WLink from "@/components/wrappers/w-link.vue";
import { listOfLanguage } from "@/util/langUtils";
import WCheckbox from "@/components/form/w-checkbox.vue";
import WText from "@/components/wrappers/w-text.vue";
let AISpeachGeneratorPanelOption = class AISpeachGeneratorPanelOption extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "duration", void 0);
    _defineProperty(this, "language", void 0);
    _defineProperty(this, "allowReorder", void 0);
    _defineProperty(this, "specificPrompt", void 0);
    _defineProperty(this, "listOfDuration", ['']);
    _defineProperty(this, "durationSelected", "");
  }
  get listOfLanguage() {
    return listOfLanguage(this.$t);
  }
  mounted() {
    this.listOfDuration = [this.$t('aiSpeachGenerator.options.durations.short').toString(), this.$t('aiSpeachGenerator.options.durations.medium').toString(), this.$t('aiSpeachGenerator.options.durations.long').toString()];
    this.durationSelected = this.duration;
  }
  onDurationSelected(value) {
    this.$emit('durationChange', value);
  }
  onLanguageChange(value) {
    this.$emit('languageChange', value);
  }
  onAllowReorderChange(value) {
    this.$emit('allowReorder', value);
  }
  onSpecificPromptChange(value) {
    this.$emit('specificPrompt', value);
  }
};
__decorate([Prop({
  default: 'Short'
})], AISpeachGeneratorPanelOption.prototype, "duration", void 0);
__decorate([Prop({
  default: ''
})], AISpeachGeneratorPanelOption.prototype, "language", void 0);
__decorate([Prop({
  default: false
})], AISpeachGeneratorPanelOption.prototype, "allowReorder", void 0);
__decorate([Prop({
  default: ''
})], AISpeachGeneratorPanelOption.prototype, "specificPrompt", void 0);
AISpeachGeneratorPanelOption = __decorate([Component({
  emits: ['durationChange', 'languageChange', 'allowReorder', 'specificPrompt'],
  components: {
    WText,
    WCheckbox,
    WLink,
    WSelect,
    AIStepsGenerator,
    WInput,
    BigButton,
    StandardButton,
    Icon
  }
})], AISpeachGeneratorPanelOption);
export default AISpeachGeneratorPanelOption;