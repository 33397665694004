import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch, Emit } from "vue-facing-decorator";
let WSelectAdvanced = class WSelectAdvanced extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "enabled", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "required", void 0);
    _defineProperty(this, "loading", void 0);
    _defineProperty(this, "valueKey", void 0);
    _defineProperty(this, "labelKey", void 0);
    _defineProperty(this, "multiple", void 0);
    _defineProperty(this, "optionAll", void 0);
    _defineProperty(this, "collapseTags", void 0);
    _defineProperty(this, "filterable", false);
    _defineProperty(this, "classClickFilter", void 0);
    _defineProperty(this, "dataTest", void 0);
    _defineProperty(this, "options", void 0);
    _defineProperty(this, "realValue", []);
  }
  mounted() {
    this.realValue = this.value;
  }
  onChangeValue() {
    return this.realValue;
  }
  onChangePropValue() {
    this.realValue = this.value;
  }
  selectAll() {
    if (this.multiple) {
      if (this.realValue.length === this.options.length) {
        this.realValue = [];
      } else {
        this.realValue = this.options.map(option => option[this.valueKey]);
      }
      this.onChangeValue();
    }
  }
  isSelected(item) {
    if (Array.isArray(this.realValue)) {
      return this.realValue.filter(currentItem => currentItem === item).length > 0;
    } else {
      return this.realValue === item;
    }
  }
};
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "icon", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WSelectAdvanced.prototype, "enabled", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "placeholder", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "label", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "required", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "loading", void 0);
__decorate([Prop({
  default: "value"
})], WSelectAdvanced.prototype, "valueKey", void 0);
__decorate([Prop({
  default: "label"
})], WSelectAdvanced.prototype, "labelKey", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "multiple", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "optionAll", void 0);
__decorate([Prop({
  default: false
})], WSelectAdvanced.prototype, "collapseTags", void 0);
__decorate([Prop()], WSelectAdvanced.prototype, "filterable", void 0);
__decorate([Prop({
  default: ''
})], WSelectAdvanced.prototype, "classClickFilter", void 0);
__decorate([Prop()], WSelectAdvanced.prototype, "dataTest", void 0);
__decorate([Prop({
  required: true,
  default: []
})], WSelectAdvanced.prototype, "options", void 0);
__decorate([Emit('change')], WSelectAdvanced.prototype, "onChangeValue", null);
__decorate([Watch('value')], WSelectAdvanced.prototype, "onChangePropValue", null);
WSelectAdvanced = __decorate([Component({})], WSelectAdvanced);
export default WSelectAdvanced;