import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import WDialog from "@/components/wrappers/w-dialog.vue";
import Icon from "@/components/icon/Icon.vue";
import axios from "axios";
import WSelect from "@/components/form/w-select.vue";
import { PeriodicReviewFrequency } from "@/models/PeriodicReviewFrequency.model";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WDatePicker from "@/components/form/w-date-picker.vue";
import { intlFormatDate } from "@/util/stringUtils";
let WorkflowValidation = class WorkflowValidation extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speachId", void 0);
    _defineProperty(this, "dateExpired", void 0);
    _defineProperty(this, "delayedDate", void 0);
    _defineProperty(this, "workflowGxp", void 0);
    _defineProperty(this, "periodicReviewEnabled", void 0);
    _defineProperty(this, "periodicReviewPopupShow", false);
    _defineProperty(this, "delayPopupShow", false);
    _defineProperty(this, "speach", void 0);
    _defineProperty(this, "periodicChoices", []);
    _defineProperty(this, "selectedPeriodicChoice", "OFF");
    _defineProperty(this, "periodicReviewFrequencies", PeriodicReviewFrequency);
    _defineProperty(this, "selectedPeriod", PeriodicReviewFrequency.ONE_YEAR.key);
    _defineProperty(this, "publicationDelays", []);
    _defineProperty(this, "selectedPublicationDelay", "SCHEDULED");
    _defineProperty(this, "delaySet", null);
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/speach');
  }
  mounted() {
    if (this.isWorkflowGxp) {
      this.selectedPeriodicChoice = "ON";
    }
    if (this.isDateExpired) {
      this.selectedPublicationDelay = "IMMEDIATELY";
    }
    this.initSpeach().then(() => {
      if (this.isPeriodicReviewEnabled) {
        this.periodicReviewPopupShow = true;
      } else if (this.delayedDate !== "") {
        this.delayPopupShow = true;
      }
      this.initPeriodicChoices();
      this.initPublicationDelays();
    });
  }
  /**
   * Initializes the periodicChoices array with predefined options.
   * Each option includes a value, a title label, and a subtitle label.
   * The labels are retrieved using internationalization methods.
   */
  initPeriodicChoices() {
    this.periodicChoices = [{
      value: "OFF",
      labelTitle: this.$t('workflowValidation.periodicChoice.off.title'),
      labelSub: this.$t('workflowValidation.periodicChoice.off.subtitle')
    }, {
      value: "ON",
      labelTitle: this.$t('workflowValidation.periodicChoice.on.title'),
      labelSub: this.$t('workflowValidation.periodicChoice.on.subtitle')
    }];
  }
  /**
   * Initializes the publication delays with predefined options for scheduling.
   * Each option includes a value, a localized title label, and a localized subtitle.
   */
  initPublicationDelays() {
    this.publicationDelays = [{
      value: "SCHEDULED",
      labelTitle: this.$tc('workflowValidation.delayChoice.scheduled.title', {
        delayDate: this.speach.delayed_till
      }),
      labelSub: this.$t('workflowValidation.delayChoice.scheduled.subtitle')
    }, {
      value: "IMMEDIATELY",
      labelTitle: this.$t('workflowValidation.delayChoice.immediately.title'),
      labelSub: this.$t('workflowValidation.delayChoice.immediately.subtitle')
    }, {
      value: "NEW_DATE",
      labelTitle: this.$t('workflowValidation.delayChoice.newDate.title'),
      labelSub: this.$t('workflowValidation.delayChoice.newDate.subtitle')
    }];
  }
  /**
   * Determines if the submit button should be disabled based on certain conditions.
   *
   * The submit button is considered disabled if all the following conditions are met:
   * - `delayPopupShow` is true, indicating that a delay popup is currently being shown.
   * - `selectedPublicationDelay` is equal to "NEW_DATE", indicating a new date is selected for publication delay.
   * - `delaySet` is null, indicating that no delay has been set.
   *
   * @return {boolean} Returns true if the submit button should be disabled, otherwise false.
   */
  get isSubmitDisabled() {
    return this.delayPopupShow && this.selectedPublicationDelay === "NEW_DATE" && this.delaySet === null;
  }
  /**
   * Determines if the publishing option for scheduled posts is disabled,
   * based on the provided radio selection value and whether the date is expired.
   *
   * @param radioValue The string representation of the selected publishing option.
   * @return Returns true if the radioValue indicates a 'SCHEDULED' option and the date is expired; otherwise, false.
   */
  isPublishScheduledDisabled(radioValue) {
    return radioValue === 'SCHEDULED' && this.isDateExpired;
  }
  /**
   * Checks if the workflow is set to GXP mode.
   *
   * @return {boolean} True if the workflow is GXP, otherwise false.
   */
  get isWorkflowGxp() {
    return this.workflowGxp === "true";
  }
  /**
   * Determines if the periodic review feature is enabled.
   *
   * This method checks the value of the periodicReviewEnabled property
   * to determine if periodic reviews are turned on. It returns true if
   * the periodicReviewEnabled is set to the string "true".
   *
   * @return {boolean} true if periodic reviews are enabled; otherwise, false.
   */
  get isPeriodicReviewEnabled() {
    return this.periodicReviewEnabled === "true";
  }
  /**
   * Checks if the date is considered expired.
   *
   * This method evaluates the internal state of `dateExpired` to determine
   * whether or not the date has expired. The state is internally represented
   * as a string and is compared to the string "true".
   *
   * @return {boolean} Returns true if the internal `dateExpired` state is
   *         equal to the string "true", indicating the date has expired.
   *         Returns false otherwise.
   */
  get isDateExpired() {
    return this.dateExpired === "true";
  }
  /**
   * Initializes the speach by fetching data from the API endpoint.
   * Sends a GET request to retrieve the speach along with its metadata based on the speach ID.
   *
   * @return {Promise<void>} A promise that resolves once the speach data has been successfully fetched and assigned.
   */
  async initSpeach() {
    return await axios.get(this.BASE_API_SPEACHES + '/speachWithMetadata', {
      params: {
        speachID: this.speachId
      }
    }).then(response => {
      this.speach = response.data;
    });
  }
  /**
   * Updates the selected period with the provided value.
   *
   * @param {string} value - The new period value to be set as the selected period.
   */
  changeSelectedPeriod(value) {
    this.selectedPeriod = value;
  }
  /**
   * Updates the delay date with a new specified value.
   *
   * @param {Date|null} newValue - The new date value to set for the delay. Can be null to clear the delay date.
   */
  changeDelayDate(newValue) {
    this.delaySet = newValue;
  }
  /**
   * Updates the state of popup displays by hiding the periodic review popup
   * and showing the delay popup.
   */
  goNext() {
    this.periodicReviewPopupShow = false;
    this.delayPopupShow = true;
  }
  /**
   * Navigates back in the user flow by updating the state of popup displays.
   * Sets `periodicReviewPopupShow` to true and `delayPopupShow` to false,
   * effectively showing the periodic review popup and hiding the delay popup.
   */
  goBack() {
    this.periodicReviewPopupShow = true;
    this.delayPopupShow = false;
  }
  /**
   * Closes the workflow validation by triggering the 'CLOSE_DELAYED_SELECTION_POPUP' event.
   */
  closeWorkflowValidation() {
    trig('CLOSE_DELAYED_SELECTION_POPUP');
  }
  /**
   * Submits the workflow validation by assessing the selected publication delay and periodic review frequency,
   * determining the appropriate new delay and periodic review next dates, and triggering the validation step process.
   * If submission is not disabled, this method will perform the following:
   * - Calculate a new delay date based on the selected publication delay option.
   * - Determine the next periodic review date based on the chosen periodic review frequency.
   * - Trigger the validation step with the calculated dates.
   */
  submitWorkflowValidation() {
    if (!this.isSubmitDisabled) {
      let newDelayDate = '';
      let periodicReviewNextDate = '';
      if (this.selectedPublicationDelay === 'NEW_DATE' && this.delaySet !== null) {
        newDelayDate = intlFormatDate(this.delaySet, this.$i18n.locale);
      } else if (this.selectedPublicationDelay === 'SCHEDULED' && this.delayedDate !== "") {
        newDelayDate = this.delayedDate;
      }
      if (this.selectedPeriodicChoice === 'ON') {
        const todayDate = new Date();
        let yearsToAdd = 1;
        if (this.selectedPeriod === PeriodicReviewFrequency.TWO_YEARS.key) {
          yearsToAdd = 2;
        } else if (this.selectedPeriod === PeriodicReviewFrequency.THREE_YEARS.key) {
          yearsToAdd = 3;
        }
        let nextReviewDate = new Date(todayDate.getFullYear() + yearsToAdd, todayDate.getMonth(), todayDate.getDate());
        periodicReviewNextDate = intlFormatDate(nextReviewDate, this.$i18n.locale);
      }
      const validationDates = newDelayDate + '||' + periodicReviewNextDate;
      this.closeWorkflowValidation();
      trig('VALIDATE_STEP', validationDates);
    }
  }
};
__decorate([Prop({
  required: true
})], WorkflowValidation.prototype, "speachId", void 0);
__decorate([Prop({
  default: "false"
})], WorkflowValidation.prototype, "dateExpired", void 0);
__decorate([Prop({
  default: ""
})], WorkflowValidation.prototype, "delayedDate", void 0);
__decorate([Prop({
  default: "false"
})], WorkflowValidation.prototype, "workflowGxp", void 0);
__decorate([Prop({
  default: "false"
})], WorkflowValidation.prototype, "periodicReviewEnabled", void 0);
WorkflowValidation = __decorate([Component({
  components: {
    WDatePicker,
    StandardButton,
    WSelect,
    Icon,
    WDialog
  }
})], WorkflowValidation);
export default WorkflowValidation;