import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-377950e9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0,
  class: "checkBefore"
};
const _hoisted_3 = {
  key: 1,
  class: "checkBefore"
};
const _hoisted_4 = {
  key: 3
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.realValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.realValue = $event),
    disabled: !_ctx.enabled,
    size: _ctx.size,
    loading: _ctx.loading,
    placeholder: _ctx.placeholder,
    multiple: _ctx.multiple,
    "collapse-tags": _ctx.collapseTags,
    filterable: _ctx.filterable,
    onChange: _ctx.onChangeValue,
    "data-test": _ctx.dataTest,
    "popper-class": _ctx.classClickFilter,
    class: "advanced-select"
  }, {
    default: _withCtx(() => [_ctx.optionAll && _ctx.multiple ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
      class: _normalizeClass('defaultSelect' + (_ctx.realValue.length === 0 ? ' selected' : ' unselected')),
      onClick: _cache[0] || (_cache[0] =
      //@ts-ignore
      (...args) => _ctx.selectAll && _ctx.selectAll(...args))
    }, [_ctx.realValue.length === _ctx.options.length ? (_openBlock(), _createBlock(_component_icon, {
      key: 0,
      icon: "checkbox-outline",
      class: "checkBefore"
    })) : _ctx.realValue.length > 0 ? (_openBlock(), _createBlock(_component_icon, {
      key: 1,
      icon: "minus-box-outline",
      class: "checkBefore"
    })) : (_openBlock(), _createBlock(_component_icon, {
      key: 2,
      icon: "checkbox-blank-outline",
      class: "checkBefore"
    })), _createTextVNode(" " + _toDisplayString(_ctx.$t('lang.select.all')), 1)], 2), _createVNode(_component_el_divider, {
      class: "listSeparator"
    })])) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item[_ctx.valueKey],
        label: item[_ctx.labelKey],
        value: item[_ctx.valueKey],
        "data-test": _ctx.dataTest + '-' + item[_ctx.valueKey]
      }, {
        default: _withCtx(() => [_ctx.multiple ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.isSelected(item[_ctx.valueKey]) ? (_openBlock(), _createBlock(_component_icon, {
          key: 0,
          icon: "checkbox-outline"
        })) : (_openBlock(), _createBlock(_component_icon, {
          key: 1,
          icon: "checkbox-blank-outline"
        }))])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_ctx.isSelected(item[_ctx.valueKey]) ? (_openBlock(), _createBlock(_component_icon, {
          key: 0,
          icon: "radiobox-marked"
        })) : (_openBlock(), _createBlock(_component_icon, {
          key: 1,
          icon: "radiobox-blank"
        }))])), _ctx.$slots.option ? _renderSlot(_ctx.$slots, "option", {
          key: 2
        }, undefined, true) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item[_ctx.labelKey]), 1))]),
        _: 2
      }, 1032, ["label", "value", "data-test"]);
    }), 128))]),
    _: 3
  }, 8, ["modelValue", "disabled", "size", "loading", "placeholder", "multiple", "collapse-tags", "filterable", "onChange", "data-test", "popper-class"]);
}