import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import { __decorate } from "tslib";
import WSelectUsers from "@/components/form/w-select-users.vue";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WAlert from "@/components/wrappers/w-alert.vue";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import WTag from "@/components/wrappers/w-tag.vue";
let ManagePeriodicReviewers = class ManagePeriodicReviewers extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speachToUpdate", void 0);
    _defineProperty(this, "classClickFilter", void 0);
    _defineProperty(this, "fieldsToUpdate", {
      users: [],
      groups: []
    });
    _defineProperty(this, "availableGroups", []);
    _defineProperty(this, "displayedGroups", []);
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/admin/speach');
    _defineProperty(this, "BASE_API_USERS", '/api/v2/admin/users');
  }
  async mounted() {
    // Assign the array to fieldsToUpdate.users
    if (this.speachToUpdate.speachMetadata.periodicReviewUsers !== undefined) {
      this.fieldsToUpdate.users = this.speachToUpdate.speachMetadata.periodicReviewUsers.map(user => this.userToUserInfos(user));
    }
    // Assign the array to fieldsToUpdate.groups
    if (this.speachToUpdate.speachMetadata.periodicReviewGroups !== undefined) {
      this.fieldsToUpdate.groups = [...this.speachToUpdate.speachMetadata.periodicReviewGroups.map(group => group.id)];
    }
    await this.initAvailableGroups();
  }
  userToUserInfos(user) {
    return {
      id: user.id,
      email: user.email,
      label: user.firstName + " " + user.lastName,
      imgUrl: user.img
    };
  }
  async initAvailableGroups() {
    await axios.get(this.BASE_API_USERS + '/groups').then(response => {
      var _this$speachToUpdate$;
      this.availableGroups = response.data.filter(group => !group.system).sort((a, b) => {
        if (a.system) {
          return -1;
        } else {
          if (b.system) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      const noGroup = {
        id: -1,
        name: this.$t('admin.dashboard.users.noGroup'),
        system: false,
        order: -1,
        description: ""
      };
      //this.availableGroups.unshift(noGroup);
      this.displayedGroups = this.availableGroups;
      this.fieldsToUpdate.groups = ((_this$speachToUpdate$ = this.speachToUpdate.speachMetadata.periodicReviewGroups) === null || _this$speachToUpdate$ === void 0 ? void 0 : _this$speachToUpdate$.map(group => group.id)) || [];
    });
  }
  async onChangeUsers(value) {
    this.fieldsToUpdate.users = value;
  }
  deleteOneUser(userToDelete) {
    let currentAffectedUsers = this.fieldsToUpdate.users || [];
    currentAffectedUsers = currentAffectedUsers.filter(user => user !== userToDelete);
    this.fieldsToUpdate.users = currentAffectedUsers;
    this.onChangeUsers(this.fieldsToUpdate.users);
  }
  async onChangeGroups(value) {
    this.displayedGroups = this.availableGroups;
    this.fieldsToUpdate.groups = value;
  }
  deleteOneGroup(groupIdToDelete) {
    var _this$fieldsToUpdate$;
    this.fieldsToUpdate.groups = (_this$fieldsToUpdate$ = this.fieldsToUpdate.groups) === null || _this$fieldsToUpdate$ === void 0 ? void 0 : _this$fieldsToUpdate$.filter(groupId => groupId !== groupIdToDelete);
  }
  groupName(groupId) {
    return this.availableGroups.filter(group => group.id === groupId).map(group => group.name)[0];
  }
  submitReviewers() {
    var _this$fieldsToUpdate$2;
    if (!this.fieldsToUpdate) {
      return;
    }
    axios.put(this.BASE_API_SPEACHES + '/update-periodic-reviewers', {
      speachId: this.speachToUpdate.speachID,
      users: JSON.stringify((_this$fieldsToUpdate$2 = this.fieldsToUpdate.users) === null || _this$fieldsToUpdate$2 === void 0 ? void 0 : _this$fieldsToUpdate$2.map(user => user.id)),
      groups: JSON.stringify(this.fieldsToUpdate.groups)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachesBatchUpdated"));
      this.$emit('reviewersSuccess');
    }, error => {
      WElMessage.showLongErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachesBatchUpdateFailed", {
        speachesFailed: this.speachToUpdate.title
      }));
    });
  }
  cancelReviewers() {
    this.$emit('reviewersCancel');
  }
};
__decorate([Prop({
  required: true
})], ManagePeriodicReviewers.prototype, "speachToUpdate", void 0);
__decorate([Prop({
  default: ''
})], ManagePeriodicReviewers.prototype, "classClickFilter", void 0);
ManagePeriodicReviewers = __decorate([Component({
  components: {
    WTag,
    WSelectAdvanced,
    WAlert,
    StandardButton,
    WSelectUsers
  },
  emits: ['batchUpdateSuccess', 'batchUpdateCancel']
})], ManagePeriodicReviewers);
export default ManagePeriodicReviewers;