export class WeetEvent{
    public name:WeetEventName;
    public data:any;
    constructor(name:WeetEventName,data:any) {
        this.name=name;
        this.data=data;
    }
}

export enum WeetEventName{
    SEND_WEET_ID="SEND_WEET_ID",
    WEET_PLAYING="WEET_PLAYING",
    WEET_PAUSING="WEET_PAUSING",
    WEET_END="WEET_END",
    SILENTLY_SEND_WEET_ID="SILENTLY_SEND_WEET_ID",
    AIAVATAR_DURATION="AIAVATAR_DURATION",
    LOGGED_OUT="LOGGED_OUT"
}
