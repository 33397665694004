import {extractDomain} from "@/util/htmlUtils";
import {WeetEvent, WeetEventName} from "@/weet/weetEvent";
import axios from "axios";
import {WeetRepresentation} from "@/models/weet.models";


export const onGenericWeetEditingMessage = (event: MessageEvent, weetEndpoint: string,chapterId:number,
                                         ):WeetEvent|null => {
  if(isAWeetMessage(event,weetEndpoint)){
    const weetEvent = event.data as WeetEvent;
    if (weetEvent.name === WeetEventName.AIAVATAR_DURATION) {
      updateTimeAvatar(weetEvent.data.weetID,chapterId,weetEvent.data.seconds)
    }
    if (weetEvent.name === WeetEventName.SILENTLY_SEND_WEET_ID) {
      createSilentlyWeet(weetEvent.data.weetID,chapterId,weetEvent.data.duration)
    }

    return weetEvent;
  }
  return null;
}

export const updateWeetRecorded=async (weetID:string,chapterId:number,duration:number):Promise<WeetRepresentation>=>{
  return await axios.post("/api/v2/recorder/weet", {
    weetID: weetID,
    duration: duration / 1000,
    chapitreId: chapterId + "",
  }).then((response) => {
    return response.data
  })
}

const createSilentlyWeet=(weetID:string,chapterId:number,duration:number)=>{
  axios.post("/api/v2/recorder/silenceWeet", {
    weetID: weetID,
    duration: duration / 1000,
    chapitreId: chapterId + "",
  }).then(() => {
    console.debug("Silently update weetID in the chapter")
  })
}

const updateTimeAvatar=(weetID:string,chapterId:number,seconds:string)=>{
  axios.put("/api/v2/recorder/updateTimeAIAvatar", {
    weetID: weetID,
    chapitreId: chapterId + "",
    seconds: seconds
  })
}



const isAWeetMessage = (event: MessageEvent, weetEndpoint: string) => {
  return extractDomain(event.origin) === extractDomain(document.location.href)
}
