export enum NotificationType {
// Type formateur
 FORMATEUR_NOUVEAU_APPRENANT = "FORMATEUR_NOUVEAU_APPRENANT",
 FORMATEUR_NOUVEAU_COMMENTAIRE = "FORMATEUR_NOUVEAU_COMMENTAIRE",
 FORMATEUR_AJOUT_FAVORIS = "FORMATEUR_AJOUT_FAVORIS",
 FORMATEUR_PARTAGE_COUR = "FORMATEUR_PARTAGE_COUR",
 FORMATEUR_REPONSE_EXAM = "FORMATEUR_REPONSE_EXAM",
 FORMATEUR_REPONSE_SONDAGE = "FORMATEUR_REPONSE_SONDAGE",
 FORMATEUR_NOUVEAU_MESSAGE = "FORMATEUR_NOUVEAU_MESSAGE",
 FORMATEUR_NOUVEAU_COEDITION = "FORMATEUR_NOUVEAU_COEDITION",
 FORMATEUR_OBTENTION_BADGE_COUR = "FORMATEUR_OBTENTION_BADGE_COUR",
 FORMATEUR_SPEACH_DUE_DATE_EXPIRED = "FORMATEUR_SPEACH_DUE_DATE_EXPIRED",

// Type Administrateur
 ADMIN_NOUVEAU_COUR_PUBLIE = "ADMIN_NOUVEAU_COUR_PUBLIE",
 ADMIN_NOUVEAU_PARCOUR_PUBLIE = "ADMIN_NOUVEAU_PARCOUR_PUBLIE",
 ADMIN_NOUVEAU_COMMENTAIRE = "ADMIN_NOUVEAU_COMMENTAIRE",
 ADMIN_NOUVEAU_APPRENANT = "ADMIN_NOUVEAU_APPRENANT",
 ADMIN_NOUVEAU_USER = "ADMIN_NOUVEAU_USER",
 ADMIN_SIGNALER_ABUS = "ADMIN_SIGNALER_ABUS",
 ADMIN_SIGNALER_ABUS_SPEACH = "ADMIN_SIGNALER_ABUS_SPEACH",
 ADMIN_OBTENTION_BADGE_COUR = "ADMIN_OBTENTION_BADGE_COUR",
 ADMIN_SPEACH_DUE_DATE_EXPIRED = "ADMIN_SPEACH_DUE_DATE_EXPIRED",
 ADMIN_SPEACH_PERIODIC_REVIEW_ASSIGN = "ADMIN_SPEACH_PERIODIC_REVIEW_ASSIGN",

// Type Apprenant
 USER_NOUVEAU_MESSAGE = "USER_NOUVEAU_MESSAGE",
 USER_NOUVEAU_AFFECTATION = "USER_NOUVEAU_AFFECTATION",
 USER_NOUVEAU_AFFECTATION_PARCOUR =
    "USER_NOUVEAU_AFFECTATION_PARCOUR",
 USER_NOUVEAU_TOPIC_MESSAGE = "USER_NOUVEAU_TOPIC_MESSAGE",
 USER_OBTENTION_BADGE_COUR = "USER_OBTENTION_BADGE_COUR",
 USER_SPEACH_DUE_DATE_APPROACHING = "USER_SPEACH_DUE_DATE_APPROACHING",

// Type Workflow
 VALIDATEUR_SPEACH_TO_VALIDATE = "VALIDATEUR_SPEACH_TO_VALIDATE",
 FORMATEUR_SPEACH_VALIDATE = "FORMATEUR_SPEACH_VALIDATE",
 FORMATEUR_SPEACH_REFUSED = "FORMATEUR_SPEACH_REFUSED",
 FORMATEUR_SPEACH_PUBLISHED = "FORMATEUR_SPEACH_PUBLISHED",
 REVIEWER_SPEACH_PERIODIC_REVIEW_ASSIGNED = "REVIEWER_SPEACH_PERIODIC_REVIEW_ASSIGNED",

}

export const notificationTypeAsMap = {
 FORMATEUR_NOUVEAU_APPRENANT: "formateur.nouveau.apprenant",
 FORMATEUR_NOUVEAU_COMMENTAIRE: "formateur.nouveau.commentaire",
 FORMATEUR_AJOUT_FAVORIS: "formateur.ajout.favoris",
 FORMATEUR_PARTAGE_COUR: "formateur.partage.cour",
 FORMATEUR_REPONSE_EXAM: "formateur.reponse.exam",
 FORMATEUR_REPONSE_SONDAGE: "formateur.reponse.sondage",
 FORMATEUR_NOUVEAU_MESSAGE: "formateur.nouveau.message",
 FORMATEUR_NOUVEAU_COEDITION: "formateur.nouveau.coedition",
 FORMATEUR_OBTENTION_BADGE_COUR: "formateur.obtention.badge.cour",
 FORMATEUR_SPEACH_DUE_DATE_EXPIRED: "formateur.speach.dueDateReached",
 ADMIN_NOUVEAU_COUR_PUBLIE: "admin.nouveau.cour.publie",
 ADMIN_NOUVEAU_PARCOUR_PUBLIE: "admin.nouveau.parcour.publie",
 ADMIN_NOUVEAU_COMMENTAIRE: "admin.nouveau.commentaire",
 ADMIN_NOUVEAU_APPRENANT: "admin.nouveau.apprenant",
 ADMIN_NOUVEAU_USER: "admin.nouveau.user",
 ADMIN_SIGNALER_ABUS: "admin.signaler.abus",
 ADMIN_SIGNALER_ABUS_SPEACH: "admin.signaler.speach",
 ADMIN_OBTENTION_BADGE_COUR: "admin.obtention.badge.cour",
 ADMIN_SPEACH_DUE_DATE_EXPIRED: "admin.speach.dueDateReached",
 ADMIN_SPEACH_PERIODIC_REVIEW_ASSIGN: "notification.admin.speach.periodicReview.assign.filterTitle",
 USER_NOUVEAU_MESSAGE: "user.nouveau.message",
 USER_NOUVEAU_AFFECTATION: "user.nouveau.affectation.speach",
 USER_NOUVEAU_AFFECTATION_PARCOUR: "user.nouveau.affectation.parcours",
 USER_NOUVEAU_TOPIC_MESSAGE: "user.nouveau.topic.message",
 USER_OBTENTION_BADGE_COUR: "user.obtention.badge.cour",
 USER_SPEACH_DUE_DATE_APPROACHING: "user.speach.dueDateApproaching",
 VALIDATEUR_SPEACH_TO_VALIDATE: "validateur.speach.to.validate",
 FORMATEUR_SPEACH_VALIDATE: "formateur.speach.validate",
 FORMATEUR_SPEACH_REFUSED: "formateur.speach.refused",
 FORMATEUR_SPEACH_PUBLISHED: "formateur.speach.published",
 FORMATEUR_SPEACH_UNPUBLISHED: "formateur.speach.unpublished",
 REVIEWER_SPEACH_PERIODIC_REVIEW_ASSIGNED: "notification.reviewer.speach.periodicReview.assigned.filterTitle",
};
