import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _VersioningVideoDownloadModal;
var VersioningVideoDownloadModal_1;
import { __decorate } from "tslib";
import WDialog from "@/components/wrappers/w-dialog.vue";
import Icon from "@/components/icon/Icon.vue";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";
import StandardButton from "@/components/buttons/StandardButton.vue";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
let VersioningVideoDownloadModal = VersioningVideoDownloadModal_1 = (_VersioningVideoDownloadModal = class VersioningVideoDownloadModal extends Vue {
  constructor(...args) {
    super(...args);
    // in seconds..
    _defineProperty(this, "visible", void 0);
    _defineProperty(this, "logId", void 0);
    _defineProperty(this, "start", void 0);
    _defineProperty(this, "fileName", void 0);
    _defineProperty(this, "intervalId", void 0);
    _defineProperty(this, "videoUrl", void 0);
    _defineProperty(this, "errorOccurred", false);
  }
  mounted() {
    this.proceedDownload();
  }
  async proceedDownload() {
    if (this.logId) {
      if (await this.attemptGetVideoUrl()) {
        return;
      }
      if (!this.errorOccurred) {
        this.intervalId = setInterval(async () => {
          await this.attemptGetVideoUrl();
        }, VersioningVideoDownloadModal_1.DOWNLOAD_RETRY_INTERVAL * 1000);
      }
    }
  }
  async attemptGetVideoUrl() {
    this.videoUrl = await this.getDownloadUrl();
    console.log('video#' + !!this.videoUrl + '#');
    return !!this.videoUrl;
  }
  async getDownloadUrl() {
    return await axios.get(`/versioning/logs/${this.logId}/video?before=${this.start}`).then(response => {
      console.log('response ', response);
      return response.data;
    }).catch(() => {
      this.errorOccurred = true;
      WElMessage.showErrorMessage(this.$t('errors.default'));
      this.closeModal();
    });
  }
  get downloadState() {
    return this.videoUrl ? 'completed' : 'inProgress';
  }
  get msgTop() {
    return this.$t(`versioning.videoDownloadModal.${this.downloadState}.msgTop`).toString();
  }
  get msgBottom() {
    return this.$t(`versioning.videoDownloadModal.${this.downloadState}.msgBottom`).toString();
  }
  saveFile() {
    const videoLink = document.createElement('a');
    videoLink.href = this.videoUrl;
    videoLink.download = this.fileName;
    videoLink.target = "_blank";
    videoLink.rel = "nofollow";
    document.body.appendChild(videoLink);
    videoLink.click();
    document.body.removeChild(videoLink);
    this.closeModal();
  }
  closeModal() {
    clearInterval(this.intervalId);
    this.$emit("on-dialog-close");
  }
  closeDialog() {
    return true;
  }
}, _defineProperty(_VersioningVideoDownloadModal, "DOWNLOAD_RETRY_INTERVAL", 15), _VersioningVideoDownloadModal);
__decorate([Prop({
  required: true
})], VersioningVideoDownloadModal.prototype, "visible", void 0);
__decorate([Prop({
  required: true
})], VersioningVideoDownloadModal.prototype, "logId", void 0);
__decorate([Prop({
  required: true
})], VersioningVideoDownloadModal.prototype, "start", void 0);
__decorate([Prop({
  required: true
})], VersioningVideoDownloadModal.prototype, "fileName", void 0);
__decorate([Emit("on-dialog-close")], VersioningVideoDownloadModal.prototype, "closeDialog", null);
VersioningVideoDownloadModal = VersioningVideoDownloadModal_1 = __decorate([Component({
  components: {
    StandardButton,
    Icon,
    WDialog
  }
})], VersioningVideoDownloadModal);
export default VersioningVideoDownloadModal;