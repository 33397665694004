import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import WSelect from "@/components/form/w-select.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import WToggle from "@/components/form/w-toggle.vue";
import delay from "delay";
import axios from "axios";
import Icon from "@/components/icon/Icon.vue";
import { Events } from "@/events/events";
import { Component, Prop, Vue } from "vue-facing-decorator";
let ScormExportDialog = class ScormExportDialog extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "scormClassicEnabled", false);
    _defineProperty(this, "idSpeach", 0);
    _defineProperty(this, "isVisible", false);
    _defineProperty(this, "url", '');
    _defineProperty(this, "scormTypeSelected", "SCORM_LITE");
    _defineProperty(this, "scormNavigationInContent", false);
    _defineProperty(this, "scormScheduledVersion", false);
    _defineProperty(this, "waitingToDownload", "");
    _defineProperty(this, "isDownloadInProgress", false);
  }
  mounted() {
    register(Events.DISPLAY_SCORM_EXPORT_DIALOG, this.displayDialog);
  }
  get scormTypes() {
    let types = [{
      value: "SCORM_LITE",
      label: this.$t('admin.dashboard.speaches.sidebar.scormExport.scormEmbed')
    }];
    if (this.scormClassicEnabled) {
      types.push({
        value: "SCORM_FULL",
        label: this.$t('admin.dashboard.speaches.sidebar.scormExport.scormClassic')
      });
    }
    return types;
  }
  displayDialog(event, idSpeach) {
    this.isVisible = true;
    this.idSpeach = idSpeach;
  }
  async startDownload() {
    if (this.idSpeach) {
      this.isDownloadInProgress = true;
      await this.launchJobScorm();
      let isFileCreated = false;
      while (!isFileCreated) {
        await delay(2000);
        isFileCreated = await this.checkIfFileCreated();
      }
      this.downloadFileUrl().then(url => {
        this.url = url;
      });
    }
  }
  async download() {
    open(this.url, "_blank");
    this.closePopup();
  }
  async launchJobScorm() {
    const result = await axios.get('/administration/scorm/exportScorm', {
      params: {
        id: this.idSpeach,
        contentNavigation: this.scormNavigationInContent,
        contentDelayed: this.scormScheduledVersion,
        type: this.scormTypeSelected
      }
    });
    this.waitingToDownload = result.data.dataValue;
  }
  async checkIfFileCreated() {
    const result = await axios.get('/administration/scorm/checkScormCreated', {
      params: {
        name: this.waitingToDownload
      }
    });
    return result.data;
  }
  async downloadFileUrl() {
    return '/administration/scorm/download/' + this.waitingToDownload;
  }
  closePopup() {
    this.isDownloadInProgress = false;
    this.isVisible = false; // useful to ensure the modal is closed. even if this component was called from speach-core html file
    this.$emit('closePopup');
  }
  beforeDestroy() {
    unregister(Events.DISPLAY_SCORM_EXPORT_DIALOG);
  }
};
__decorate([Prop()], ScormExportDialog.prototype, "scormClassicEnabled", void 0);
ScormExportDialog = __decorate([Component({
  components: {
    Icon,
    WToggle,
    WDialog,
    StandardButton,
    WSelect
  },
  emits: ['closePopup']
})], ScormExportDialog);
export default ScormExportDialog;