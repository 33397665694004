import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { WeetEventName } from "@/weet/weetEvent";
import { extractDomain } from "@/util/htmlUtils";
import { Component, Prop, Vue } from "vue-facing-decorator";
let Logout = class Logout extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "weetEndpoint", void 0);
    _defineProperty(this, "timeoutId", void 0);
  }
  get weetLogoutUrl() {
    return `${this.weetEndpoint}/logout`;
  }
  mounted() {
    const onWeetMessageReceive = this.onWeetMessageReceive;
    window.addEventListener('message', onWeetMessageReceive);
    this.timeoutId = setTimeout(() => {
      this.speachLogout();
    }, 5000);
  }
  onWeetMessageReceive(event) {
    if (extractDomain(event.origin) === extractDomain(document.location.href) || extractDomain(event.origin) === extractDomain(this.weetEndpoint)) {
      const objData = event.data;
      if (objData.name === WeetEventName.LOGGED_OUT) {
        console.log('Logged out from weet.');
        this.speachLogout();
        this.removeWeetMessageListener();
      }
    }
  }
  speachLogout() {
    window.location.href = window.location.origin + `/logout`;
  }
  removeWeetMessageListener() {
    window.removeEventListener("message", this.onWeetMessageReceive);
  }
  beforeDestroy() {
    clearTimeout(this.timeoutId);
    this.removeWeetMessageListener();
  }
};
__decorate([Prop({
  required: true
})], Logout.prototype, "weetEndpoint", void 0);
Logout = __decorate([Component], Logout);
export default Logout;