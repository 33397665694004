import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import AuthorIconGroupPicto from "@/components/card/authorIcon/authorIconGroup/AuthorIconGroupPicto.vue";
import { goToSpeach, goToUser, toParcoursPath, toSpeachPath, toSpeachToValidatePath, toUserPath } from "@/util/routeUtil";
import AuthorIconGroup from "@/components/card/authorIcon/AuthorIconGroup.vue";
import { getNotificationMessage } from "@/util/notificationUtils";
import { formatDate } from "@/util/stringUtils";
import Icon from "@/components/icon/Icon.vue";
import Rating from "@/components/rating/Rating.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { NotificationType } from "@/models/notificationType";
let NotificationElt = class NotificationElt extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "notification", void 0);
  }
  get messageTitle() {
    return getNotificationMessage(this.notification, this.$t, this.linkToAuthor, this.linkToSpeach, '', '', this.linkToParcours, this.addDefaultStyle);
  }
  get date() {
    return formatDate(new Date(this.notification.date));
  }
  get linkToAuthor() {
    if (this.notification.sourceUser.anonymous) {
      return this.$t('notification.anonymous.user.name');
    } else {
      return `<a href="${toUserPath(this.notification.sourceUser)}" class="notificationLink">${this.notification.sourceUser.firstName} ${this.notification.sourceUser.lastName}</a>`;
    }
  }
  get linkToSpeach() {
    const speach = this.notification.speach;
    if (this.notification.speach) {
      if (this.notification.typeNotification === NotificationType.VALIDATEUR_SPEACH_TO_VALIDATE) {
        return this.getWorkflowLinkToSpeach(speach);
      } else {
        return this.buildLinkToSpeach(this.notification.speach.title, toSpeachPath(speach));
      }
    } else {
      return '';
    }
  }
  getWorkflowLinkToSpeach(speach) {
    if (this.notification.speach.isWorkflowInProgress) {
      return this.buildLinkToSpeach(speach.title, toSpeachToValidatePath(speach));
    } else {
      return this.buildLinkToSpeach(speach.title, toSpeachPath(speach));
    }
  }
  buildLinkToSpeach(speachTitle, linkPath) {
    return `<a href="${linkPath}" class="notificationLink">${speachTitle}</a>`;
  }
  get linkToParcours() {
    if (this.notification.parcours) {
      return `<a href="${toParcoursPath(this.notification.parcours)}" class="notificationLink">${this.notification.parcours.title}</a>`;
    } else {
      return '';
    }
  }
  addDefaultStyle(text) {
    return `<div class="emphasedText">${text}</div>`;
    // return `<div style="font-weight: 600; color: #666666;display: inline-block;">${text}</div>`;
  }
  goToSpeach() {
    goToSpeach(this.notification.speach);
  }
  goToParcours() {
    // goToParcours(this.notification.parcours)
  }
  goToUser() {
    if (!this.notification.sourceUser.anonymous) {
      goToUser(this.notification.sourceUser);
    }
  }
};
__decorate([Prop({
  required: true
})], NotificationElt.prototype, "notification", void 0);
NotificationElt = __decorate([Component({
  components: {
    Rating,
    Icon,
    AuthorIconGroup,
    AuthorIconGroupPicto
  }
})], NotificationElt);
export default NotificationElt;