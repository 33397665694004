import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1723cafe"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "loaderFullScreenElement"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_dialog = _resolveComponent("w-dialog");
  const _component_loader = _resolveComponent("loader");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_w_dialog, {
    visible: !!_ctx.urlEditing,
    fullscreen: true,
    "custom-class": 'backgroundOverlayEditingWeet',
    closeOnOutsideClick: false
  }, {
    default: _withCtx(() => [_ctx.urlEditing ? (_openBlock(), _createElementBlock("iframe", {
      key: 0,
      onLoad: _cache[0] || (_cache[0] =
      //@ts-ignore
      (...args) => _ctx.iFrameLoaded && _ctx.iFrameLoaded(...args)),
      "data-test": "weetIframe",
      src: _ctx.urlEditing,
      class: "iFrameFullForEditing",
      allow: "fullscreen *;camera *;microphone *;display-capture *;autoplay *"
    }, null, 40, _hoisted_1)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible"]), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_2, [_createVNode(_component_loader)], 512), [[_vShow, _ctx.loadingInterface]])]),
    _: 1
  })]);
}