import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-eb7edef8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "workflowValidationHeader"
};
const _hoisted_2 = {
  key: 0,
  class: "workflowValidationPeriodicPopup",
  "data-test": "workflow-validation-periodic-review"
};
const _hoisted_3 = {
  class: "workflowValidationHeaderTitle"
};
const _hoisted_4 = {
  class: "workflowValidationMainTitle"
};
const _hoisted_5 = {
  class: "workflowValidationForm"
};
const _hoisted_6 = {
  class: "workflowValidationInputTitle"
};
const _hoisted_7 = {
  class: "workflowValidationInputSub"
};
const _hoisted_8 = {
  class: "workflowValidationSelectBox"
};
const _hoisted_9 = {
  key: 1,
  class: "workflowValidationDelayPopup",
  "data-test": "workflow-validation-delay-review"
};
const _hoisted_10 = {
  class: "workflowValidationHeaderTitle"
};
const _hoisted_11 = {
  class: "workflowValidationMainTitle"
};
const _hoisted_12 = {
  class: "workflowValidationSubtitle"
};
const _hoisted_13 = {
  class: "workflowValidationForm"
};
const _hoisted_14 = {
  class: "workflowValidationInputTitle"
};
const _hoisted_15 = {
  class: "workflowValidationInputSub"
};
const _hoisted_16 = {
  class: "workflowValidationSelectBox"
};
const _hoisted_17 = {
  key: 2,
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_w_select = _resolveComponent("w-select");
  const _component_w_date_picker = _resolveComponent("w-date-picker");
  const _component_standard_button = _resolveComponent("standard-button");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_withDirectives(_createVNode(_component_icon, {
    icon: "arrow-left",
    size: "default",
    class: "workflowValidationIcon",
    onClick: _ctx.goBack
  }, null, 8, ["onClick"]), [[_vShow, _ctx.delayPopupShow && _ctx.isPeriodicReviewEnabled]]), _createVNode(_component_icon, {
    icon: "close",
    size: "default",
    class: "workflowValidationIcon lastIcon",
    onClick: _ctx.closeWorkflowValidation
  }, null, 8, ["onClick"])]), _ctx.periodicReviewPopupShow ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.isWorkflowGxp ? _ctx.$t('workflowValidation.periodicChoice.titleGXP') : _ctx.$t('workflowValidation.periodicChoice.title')), 1)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_radio_group, {
    modelValue: _ctx.selectedPeriodicChoice,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectedPeriodicChoice = $event),
    class: "workflowValidationRadioGroup"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periodicChoices, periodicChoice => {
      return _openBlock(), _createBlock(_component_el_radio, {
        class: "workflowValidationRadio",
        disabled: _ctx.isWorkflowGxp && periodicChoice.value === 'OFF',
        key: periodicChoice.value,
        value: periodicChoice.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_6, _toDisplayString(periodicChoice.labelTitle), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(periodicChoice.labelSub), 1)]),
        _: 2
      }, 1032, ["disabled", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_w_select, {
    "value-key": "key",
    options: _ctx.periodicReviewFrequencies,
    "translate-label": true,
    disabled: _ctx.selectedPeriodicChoice === 'OFF',
    onChange: _ctx.changeSelectedPeriod,
    value: _ctx.selectedPeriod,
    "data-test": "dashboard-period-selection"
  }, null, 8, ["options", "disabled", "onChange", "value"])])])])) : _createCommentVNode("", true), _ctx.delayPopupShow ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('workflowValidation.delayChoice.title')), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('workflowValidation.delayChoice.subtitle')), 1)]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_el_radio_group, {
    modelValue: _ctx.selectedPublicationDelay,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.selectedPublicationDelay = $event),
    class: "workflowValidationRadioGroup"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.publicationDelays, publicationDelay => {
      return _openBlock(), _createBlock(_component_el_radio, {
        class: "workflowValidationRadio",
        disabled: _ctx.isPublishScheduledDisabled(publicationDelay.value),
        key: publicationDelay.value,
        value: publicationDelay.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_14, _toDisplayString(publicationDelay.labelTitle), 1), _createElementVNode("div", _hoisted_15, _toDisplayString(publicationDelay.labelSub), 1)]),
        _: 2
      }, 1032, ["disabled", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_w_date_picker, {
    disabled: _ctx.selectedPublicationDelay !== 'NEW_DATE',
    class: "dueDateFormDateInput",
    placeholder: _ctx.$t('speach.metadata.dueDate.placeholder'),
    calendar: _ctx.delaySet,
    onChangeCalendar: _ctx.changeDelayDate,
    separator: "-",
    placement: "bottom-end"
  }, null, 8, ["disabled", "placeholder", "calendar", "onChangeCalendar"])])])])) : _createCommentVNode("", true), _ctx.speach !== undefined ? (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_standard_button, {
    outlined: true,
    label: _ctx.$t('messageBox.cancel'),
    onClick: _ctx.closeWorkflowValidation
  }, null, 8, ["label", "onClick"]), _ctx.periodicReviewPopupShow && _ctx.delayedDate !== '' ? (_openBlock(), _createBlock(_component_standard_button, {
    key: 0,
    label: _ctx.$t('messageBox.next'),
    onClick: _ctx.goNext
  }, null, 8, ["label", "onClick"])) : (_openBlock(), _createBlock(_component_standard_button, {
    key: 1,
    disabled: _ctx.isSubmitDisabled,
    label: _ctx.$t('messageBox.submit'),
    onClick: _ctx.submitWorkflowValidation
  }, null, 8, ["disabled", "label", "onClick"]))])) : _createCommentVNode("", true)], 64);
}