import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-591324c0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "subtitle"
};
const _hoisted_2 = {
  class: "dialogMaxHeight"
};
const _hoisted_3 = {
  class: "dialogShrinkedWhenScrollbarOn"
};
const _hoisted_4 = {
  class: "labelBold"
};
const _hoisted_5 = {
  class: "labelBold"
};
const _hoisted_6 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_select_advanced = _resolveComponent("w-select-advanced");
  const _component_w_tag = _resolveComponent("w-tag");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_confirm_dialog = _resolveComponent("confirm-dialog");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('admin.dashboard.users.multiselect.information')), 1), _createVNode(_component_el_form, {
    model: _ctx.fieldsToUpdate,
    "label-width": "auto",
    "label-position": "top",
    style: {
      "max-width": "100%"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_scrollbar, {
      "max-height": "410px"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_form_item, null, {
        label: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('admin.dashboard.users.multiselect.groups')), 1)]),
        default: _withCtx(() => [_createVNode(_component_w_select_advanced, {
          options: _ctx.displayedGroups,
          class: "selectFullWidth selectWithoutTags",
          onChange: _ctx.onChangeGroups,
          value: _ctx.fieldsToUpdate.groups,
          placeholder: _ctx.$t('admin.dashboard.users.multiselect.noChanges'),
          "value-key": "id",
          "label-key": "name",
          multiple: true,
          filterable: true
        }, null, 8, ["options", "onChange", "value", "placeholder"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsToUpdate.groups, group => {
          return _openBlock(), _createBlock(_component_w_tag, {
            textTag: _ctx.groupName(group),
            key: group,
            round: "",
            onRemoveTag: $event => _ctx.deleteOneGroup(group)
          }, null, 8, ["textTag", "onRemoveTag"]);
        }), 128))])]),
        _: 1
      }), _createVNode(_component_el_form_item, null, {
        label: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('admin.dashboard.users.multiselect.roles')), 1)]),
        default: _withCtx(() => [_createVNode(_component_w_select_advanced, {
          options: _ctx.displayedRoles,
          class: "selectFullWidth selectWithoutTags",
          onChange: _ctx.onChangeRoles,
          value: _ctx.fieldsToUpdate.roles,
          placeholder: _ctx.$t('admin.dashboard.users.multiselect.noChanges'),
          "value-key": "key",
          "label-key": "name",
          multiple: true,
          filterable: true
        }, null, 8, ["options", "onChange", "value", "placeholder"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsToUpdate.roles, role => {
          return _openBlock(), _createBlock(_component_w_tag, {
            textTag: _ctx.roleName(role),
            key: role,
            round: "",
            onRemoveTag: $event => _ctx.deleteOneRole(role)
          }, null, 8, ["textTag", "onRemoveTag"]);
        }), 128))])]),
        _: 1
      })])]),
      _: 1
    })]), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_standard_button, {
        outlined: true,
        label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.cancel'),
        onClick: _ctx.cancelBatchUpdate
      }, null, 8, ["label", "onClick"]), _createVNode(_component_standard_button, {
        label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.ok'),
        onClick: _cache[0] || (_cache[0] = $event => _ctx.confirmUpdatePopup = true)
      }, null, 8, ["label"])])]),
      _: 1
    }), _createVNode(_component_confirm_dialog, {
      visible: _ctx.confirmUpdatePopup,
      title: _ctx.$tc('admin.dashboard.users.batchEdit', _ctx.usersToUpdate.length, {
        count: _ctx.usersToUpdate.length
      }),
      message: _ctx.$t('admin.dashboard.users.batchEditConfirmation'),
      "close-on-outside-click": false,
      onOnConfirmed: _cache[1] || (_cache[1] = $event => _ctx.submitBatchUpdate()),
      onOnDialogClose: _cache[2] || (_cache[2] = $event => _ctx.confirmUpdatePopup = false),
      onOnCanceled: _cache[3] || (_cache[3] = $event => _ctx.confirmUpdatePopup = false)
    }, null, 8, ["visible", "title", "message"])]),
    _: 1
  }, 8, ["model"])], 64);
}