import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9f7fd9a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialogMaxHeight"
};
const _hoisted_2 = {
  class: "dialogShrinkedWhenScrollbarOn"
};
const _hoisted_3 = {
  class: "labelBold"
};
const _hoisted_4 = {
  class: "labelBold"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_select_users = _resolveComponent("w-select-users");
  const _component_w_tag = _resolveComponent("w-tag");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_w_select_advanced = _resolveComponent("w-select-advanced");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    model: _ctx.fieldsToUpdate,
    "label-width": "auto",
    "label-position": "top",
    style: {
      "max-width": "100%"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_scrollbar, {
      "max-height": "410px"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form_item, null, {
        label: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('admin.dashboard.speaches.periodicReview.manageReviewers.searchUsers')), 1)]),
        default: _withCtx(() => [_createVNode(_component_w_select_users, {
          onChange: _ctx.onChangeUsers,
          class: "selectFullWidth selectWithoutTags",
          value: _ctx.fieldsToUpdate.users,
          "value-key": "id",
          "label-key": "email",
          multiple: true,
          placeholder: _ctx.$t('admin.dashboard.speaches.periodicReview.manageReviewers.searchUsersPlaceholder'),
          "class-click-filter": _ctx.classClickFilter,
          "data-test": "add-periodic-users-select"
        }, null, 8, ["onChange", "value", "placeholder", "class-click-filter"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsToUpdate.users, user => {
          return _openBlock(), _createBlock(_component_w_tag, {
            textTag: user.label,
            key: user.id,
            round: "",
            onRemoveTag: $event => _ctx.deleteOneUser(user)
          }, null, 8, ["textTag", "onRemoveTag"]);
        }), 128))])]),
        _: 1
      }), _createVNode(_component_el_form_item, null, {
        label: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('admin.dashboard.speaches.periodicReview.manageReviewers.searchGroups')), 1)]),
        default: _withCtx(() => [_createVNode(_component_w_select_advanced, {
          options: _ctx.displayedGroups,
          class: "selectWithoutTags",
          onChange: _ctx.onChangeGroups,
          value: _ctx.fieldsToUpdate.groups,
          "value-key": "id",
          "label-key": "name",
          filterable: true,
          multiple: true,
          placeholder: _ctx.$t('admin.dashboard.speaches.periodicReview.manageReviewers.searchGroupsPlaceholder'),
          "class-click-filter": _ctx.classClickFilter
        }, null, 8, ["options", "onChange", "value", "placeholder", "class-click-filter"]), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsToUpdate.groups, group => {
          return _openBlock(), _createBlock(_component_w_tag, {
            textTag: _ctx.groupName(group),
            key: group,
            round: "",
            onRemoveTag: $event => _ctx.deleteOneGroup(group)
          }, null, 8, ["textTag", "onRemoveTag"]);
        }), 128))])]),
        _: 1
      })])]),
      _: 1
    })]), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_standard_button, {
        outlined: true,
        label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.cancel'),
        onClick: _ctx.cancelReviewers
      }, null, 8, ["label", "onClick"]), _createVNode(_component_standard_button, {
        label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.ok'),
        onClick: _ctx.submitReviewers
      }, null, 8, ["label", "onClick"])])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model"]);
}