import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { __decorate } from "tslib";
import axios from "axios";
import { debounce } from "lodash";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let UserSearchCompact = class UserSearchCompact extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "alreadySelectedUsers", void 0);
    _defineProperty(this, "searchTerm", '');
    _defineProperty(this, "users", []);
    _defineProperty(this, "totalNbOfUsers", 0);
    _defineProperty(this, "page", 1);
    _defineProperty(this, "count", 10);
    _defineProperty(this, "selectedUsers", []);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "noMore", true);
    _defineProperty(this, "throttledLoadMoreUsers", debounce(this.loadMoreUsers, 500));
  }
  mounted() {
    this.updateSelectedUsers();
  }
  updateSelectedUsers() {
    this.selectedUsers = this.alreadySelectedUsers;
  }
  get infiniteScrollDisabled() {
    return this.loading || this.noMore;
  }
  searchUsers(query) {
    this.page = 1;
    this.users = [];
    this.noMore = false;
    if (query) {
      this.searchTerm = query;
      this.loadMoreUsers();
    } else {
      this.searchTerm = '';
    }
  }
  loadMoreUsers() {
    if (!this.infiniteScrollDisabled) {
      this.loading = true;
      axios.get("/api/v2/users", {
        params: {
          dataType: "json",
          term: this.searchTerm,
          count: this.count,
          // page: this.users.page
          page: this.page
        }
      }).then(response => {
        const newUsers = response.data.results;
        newUsers.forEach(user => this.users.push(user));
        this.page++;
        this.totalNbOfUsers = response.data.totalNumberOfUsersFound;
        if (this.users.length >= response.data.totalNumberOfUsersFound) {
          this.noMore = true;
        }
      }, error => {
        console.error('Error while loading users list: ' + error);
        this.noMore = true;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
  pushChangedValues(event) {
    this.$emit('selectedUsers', this.selectedUsers);
  }
};
__decorate([Prop({
  default: []
})], UserSearchCompact.prototype, "alreadySelectedUsers", void 0);
__decorate([Watch('alreadySelectedUsers', {
  immediate: true
})], UserSearchCompact.prototype, "updateSelectedUsers", null);
UserSearchCompact = __decorate([Component({})], UserSearchCompact);
export default UserSearchCompact;