import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ab32c1c8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "doughnut-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Doughnut = _resolveComponent("Doughnut");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.loading ? (_openBlock(), _createBlock(_component_Doughnut, {
    key: 0,
    ref: "doughnutchart",
    data: _ctx.dataGraph,
    options: _ctx.options,
    plugins: _ctx.plugins
  }, null, 8, ["data", "options", "plugins"])) : _createCommentVNode("", true)]);
}