import {PDFPage} from "@/models/aiSpeachGenerator.models";
import {encodeB64Unicode} from "@/util/stringUtils";

export class WeetInitiator {
  type:"DOCUMENT";
  documentPages: PDFPage[]=[];
  hiddenInstructionB64:string|null=null;
  lang:string|null=null;
  constructor(type:"DOCUMENT",documentPages:PDFPage[],hiddenInstruction:string|null=null,
              lang:string|null=null) {
    this.type=type;
    this.lang=lang;
    this.documentPages=documentPages;
    if(hiddenInstruction) {
      this.hiddenInstructionB64 = encodeB64Unicode(hiddenInstruction);
    }
  }
}
