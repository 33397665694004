// @ts-ignore
import linkifyHtml from 'linkify-html';
import xss from 'xss';
import slugify from "slugify";


export const replaceStringLineBreakByBr = (input: string): string => {
    return input?.replace(/(?:\r\n|\r|\n)/g, '<br/>');
};
export const htmliFy = (value: string,noBrReplace:boolean=false): string => {
    let html = linkifyHtml(value, {
        defaultProtocol: 'https',
    });
    if(!noBrReplace) {
        html = replaceStringLineBreakByBr(html);
    }
    return xss(html, {
        whiteList: {
            a: ['href', 'title', 'target'],
            br: []
        }
    });
};

export const formatDate=(date:Date)=>{
  return date.toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'});
}

/**
 * Formats a given date according to the specified locale.
 *
 * @param {Date} date - The date to be formatted.
 * @param {string} locale - The locale code used for formatting.
 *                          Supported locales are 'fr', 'es', 'de', and default ('en').
 *
 * @returns {string} The formatted date string.
 */
export const intlFormatDate = (date: Date, locale: string) => {
  let isoLocaleForDate = 'en-US';
  if (locale === 'fr') {
    isoLocaleForDate = 'fr-FR';
  } else if (locale === 'es') {
    isoLocaleForDate = 'es-ES';
  } else if (locale === 'de') {
    isoLocaleForDate = 'de-DE';
  }
  let formattedDate = date.toLocaleDateString(isoLocaleForDate, {year: 'numeric', month: 'numeric', day: 'numeric'});
  // We are forced to replace separators for the locales 'en' and 'de',
  // because the date formats used by the backend are non-standard.
  if (locale === 'en') {
    formattedDate = formattedDate.replaceAll('/', '-');
  } else if (locale === 'de') {
    formattedDate = formattedDate.replaceAll('.', '/');
  }
  return formattedDate;
}

export function stringToURL(s:string, defaultString: string):string {
  const result = slugify(s);
  return result ? result : defaultString;
}

export function encodeB64Unicode(str:string):string {
  // Convert string to UTF-8 byte array using TextEncoder
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(str);

  // Convert byte array to binary string and then to Base64
  let binaryString = '';
  uint8Array.forEach(byte => {
    binaryString += String.fromCharCode(byte);
  });

  return btoa(binaryString);
}
