import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-43de5bee"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loader = _resolveComponent("loader");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_loader, {
    "full-screen": true,
    "loader-msg": "#{l code:'logout.loaderMsg'/}"
  }), _createElementVNode("iframe", {
    src: _ctx.weetLogoutUrl,
    class: "logoutIFrame",
    width: "0",
    height: "0"
  }, null, 8, _hoisted_1)], 64);
}