import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  return _openBlock(), _createBlock(_component_el_checkbox_group, {
    modelValue: _ctx.checkedItems,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.checkedItems = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, item => {
      return _openBlock(), _createBlock(_component_w_tool_tip, {
        key: item[_ctx.valueKey],
        text: item['tooltip'],
        placement: item['tooltipPlacement'] || 'bottom'
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_checkbox, {
          label: item[_ctx.valueKey],
          key: item[_ctx.valueKey],
          value: item[_ctx.valueKey],
          disabled: item['disabled'],
          onChange: $event => _ctx.onChangeOneItem($event, item[_ctx.labelKey], item[_ctx.valueKey])
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item[_ctx.labelKey]), 1)]),
          _: 2
        }, 1032, ["label", "value", "disabled", "onChange"]))]),
        _: 2
      }, 1032, ["text", "placement"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]);
}