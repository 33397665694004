import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { Bar } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { getLegend, getRotation, getTooltip } from "@/util/chartOptions";
import dayjs from "dayjs";
let DashboardBarChart = class DashboardBarChart extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "graphs", void 0);
    _defineProperty(this, "doRotate", void 0);
    _defineProperty(this, "legendTooltipId", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "datasets", []);
    _defineProperty(this, "startDates", []);
    _defineProperty(this, "endDates", []);
    _defineProperty(this, "legends", []);
    _defineProperty(this, "loading", false);
  }
  mounted() {
    Chart.register(...registerables);
    setTimeout(() => {
      this.loading = true;
    }, 10);
  }
  onDataChange() {
    if (this.graphs) {
      this.startDates = this.graphs[0].data.map(d => dayjs(d.startDate).format(this.$t('formatDateDayMonth').toString()));
      this.endDates = this.graphs[0].data.map(d => dayjs(d.endDate).format(this.$t('formatDateDayMonth').toString()));
      this.datasets = this.graphs.map(g => {
        return {
          label: g.legend,
          fill: true,
          tension: 2,
          backgroundColor: g.rgbaColor,
          barThickness: "flex",
          barPercentage: 0.7,
          data: g.data.map(d => d.value),
          tooltipFirstPeriodDates: this.startDates,
          tooltipSecondPeriodDates: this.endDates
        };
      });
      this.legends = this.graphs.map(g => g.tooltipLegend);
    }
  }
  get dataGraph() {
    // Overwriting base render method with actual data.
    return {
      labels: this.endDates,
      datasets: this.datasets
    };
  }
  get options() {
    return {
      plugins: {
        legend: getLegend(this.legends, this.legendTooltipId),
        tooltip: getTooltip("nearest", this.title, this.$t)
      },
      scales: {
        y: {
          grid: {
            drawBorder: false
          },
          beginAtZero: true,
          ticks: {
            callback: value => {
              if (value % 1 === 0) {
                return value;
              } else {
                return null;
              }
            }
          }
        },
        x: {
          ticks: getRotation(this.doRotate),
          grid: {
            drawOnChartArea: false,
            offsetGridLines: false
          }
        }
      }
    };
  }
};
__decorate([Prop({
  required: true
})], DashboardBarChart.prototype, "graphs", void 0);
__decorate([Prop({
  default: false
})], DashboardBarChart.prototype, "doRotate", void 0);
__decorate([Prop({
  default: ''
})], DashboardBarChart.prototype, "legendTooltipId", void 0);
__decorate([Prop({
  default: ''
})], DashboardBarChart.prototype, "title", void 0);
__decorate([Watch("graphs")], DashboardBarChart.prototype, "onDataChange", null);
DashboardBarChart = __decorate([Component({
  components: {
    Bar
  }
})], DashboardBarChart);
export default DashboardBarChart;