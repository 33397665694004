import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import BigButton from "@/components/buttons/BigButton.vue";
import WInput from "@/components/form/w-input.vue";
import axios from "axios";
import { alertError } from "@/util/dialog";
import Loader from "@/components/loader/Loader.vue";
import LightButton from "@/components/buttons/LightButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import delay from "delay";
import { WeetEventName } from "@/weet/weetEvent";
import { onGenericWeetEditingMessage, updateWeetRecorded } from "@/util/weetMessageUtil";
import { v4 as uuidv4 } from 'uuid';
import { WeetInitiator } from "@/models/weetInitiator/WeetInitiator";
let AIChapterContentGenerator = class AIChapterContentGenerator extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speachGenerated", void 0);
    _defineProperty(this, "speachToMap", void 0);
    _defineProperty(this, "indexLecon", void 0);
    _defineProperty(this, "indexChapter", void 0);
    _defineProperty(this, "weetEndpoint", void 0);
    _defineProperty(this, "aiFiles", []);
    _defineProperty(this, "language", void 0);
    _defineProperty(this, "urlEditing", void 0);
    _defineProperty(this, "loadingInterface", false);
    _defineProperty(this, "chapter", null);
    _defineProperty(this, "weetID", "");
  }
  async mounted() {
    this.loadingInterface = true;
    // get chapter with index
    this.chapter = this.speachToMap.lecons[this.indexLecon].chapitres[this.indexChapter];
    // create the weetInitiator object
    const initID = uuidv4();
    this.createAndSaveWeetInitiator(initID);
    this.urlEditing = await axios.get('/api/v2/recorder/getUrlEditingForChapter/' + this.chapter.id).then(response => response.data);
    this.urlEditing += "&initID=" + initID;
    window.addEventListener('message', this.onMessageReceive);
    this.cleanWeetInitiatorWithDelay(initID);
  }
  onClose(weetID) {
    this.$emit('onClose', weetID);
    // send the close element
    window.removeEventListener("message", this.onMessageReceive);
  }
  async onMessageReceive(event) {
    if (this.chapter) {
      const weetEvent = onGenericWeetEditingMessage(event, this.weetEndpoint, this.chapter.id);
      if (weetEvent) {
        if (weetEvent.name === WeetEventName.SEND_WEET_ID) {
          this.weetID = weetEvent.data.weetID;
          if (weetEvent.data.modified) {
            try {
              await updateWeetRecorded(this.weetID, this.chapter.id, weetEvent.data.duration);
            } catch (error) {
              console.error("error on save weet", error);
              alertError(error.toString());
            }
          }
          this.onClose(this.weetID);
        }
      }
    }
  }
  async iFrameLoaded() {
    await delay(1500);
    this.loadingInterface = false;
  }
  async createAndSaveWeetInitiator(initID) {
    const listOfDocumentPage = this.speachGenerated.chapters[this.indexLecon].subchapters[this.indexChapter].documentPages;
    const listOfPDFPage = [];
    for (const aiDocumentPage of listOfDocumentPage) {
      const indexDoc = parseInt(aiDocumentPage.documentName.split("_")[1]);
      const file = this.aiFiles[indexDoc - 1];
      const pdfPage = file.data[aiDocumentPage.pageNumber - 1];
      listOfPDFPage.push(pdfPage);
    }
    const indexSubChapter = this.indexLecon + 1 + "-" + (this.indexChapter + 1);
    // create a prompt
    let hiddenPrompt = "Generate a script only for the subChapter: <subChapterSelected>" + indexSubChapter + "</subChapterSelected>" + "of this training module: '" + this.speachGenerated.title + "' <trainingModule>" + JSON.stringify(this.speachGenerated) + "</trainingModule>\n";
    hiddenPrompt += "Generate the script according the plan of the training module.\n";
    hiddenPrompt += "We provided you only the slides of this specific subChapter";
    const initObject = new WeetInitiator("DOCUMENT", listOfPDFPage, hiddenPrompt, this.language);
    localStorage.setItem(initID, JSON.stringify(initObject));
  }
  cleanWeetInitiatorWithDelay(initID, delay = 10000) {
    // only 10 seconds to get the initObject
    setTimeout(() => {
      localStorage.removeItem(initID);
    }, delay);
  }
};
__decorate([Prop({
  required: true
})], AIChapterContentGenerator.prototype, "speachGenerated", void 0);
__decorate([Prop({
  required: true
})], AIChapterContentGenerator.prototype, "speachToMap", void 0);
__decorate([Prop({
  required: true
})], AIChapterContentGenerator.prototype, "indexLecon", void 0);
__decorate([Prop({
  required: true
})], AIChapterContentGenerator.prototype, "indexChapter", void 0);
__decorate([Prop({
  required: true
})], AIChapterContentGenerator.prototype, "weetEndpoint", void 0);
__decorate([Prop({
  required: true
})], AIChapterContentGenerator.prototype, "aiFiles", void 0);
__decorate([Prop({
  required: true
})], AIChapterContentGenerator.prototype, "language", void 0);
AIChapterContentGenerator = __decorate([Component({
  components: {
    WDialog,
    IconButton,
    LightButton,
    Loader,
    WInput,
    BigButton,
    StandardButton,
    Icon
  },
  emits: ['onClose']
})], AIChapterContentGenerator);
export default AIChapterContentGenerator;