import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-037c5d4a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "download-in-progress-container"
};
const _hoisted_2 = {
  class: "download-in-progress-container__msg"
};
const _hoisted_3 = {
  class: "download-in-progress-container__msg"
};
const _hoisted_4 = {
  key: 0,
  class: "spinner-block"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loader = _resolveComponent("loader");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: _ctx.$t('versioning.videoDownloadModal.title'),
    onOnDialogClose: _ctx.closeModal
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_5, [_createVNode(_component_standard_button, {
      outlined: true,
      label: _ctx.$t('versioning.videoDownloadModal.cancelBtn'),
      onClick: _cache[0] || (_cache[0] = $event => _ctx.closeModal())
    }, null, 8, ["label"]), _createVNode(_component_standard_button, {
      disabled: !_ctx.videoUrl,
      label: _ctx.$t('versioning.videoDownloadModal.downloadBtn'),
      onClick: _cache[1] || (_cache[1] = $event => _ctx.saveFile())
    }, null, 8, ["disabled", "label"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.msgTop), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.msgBottom), 1)]), !_ctx.videoUrl ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_loader, {
      "spinner-size": 'spinnersNormal'
    })])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["visible", "title", "onOnDialogClose"]);
}