import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import VersioningVideoDownloadModal from "@/components/versioning/VersioningVideoDownloadModal.vue";
let VersioningVideoDownloadButton = class VersioningVideoDownloadButton extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "logId", void 0);
    _defineProperty(this, "start", void 0);
    _defineProperty(this, "fileName", void 0);
    _defineProperty(this, "displayModal", false);
  }
  toggleDownloadModal() {
    if (this.logId) {
      this.displayModal = true;
    }
  }
  closeModal() {
    this.displayModal = false;
  }
};
__decorate([Prop({
  required: true
})], VersioningVideoDownloadButton.prototype, "logId", void 0);
__decorate([Prop({
  required: true
})], VersioningVideoDownloadButton.prototype, "start", void 0);
__decorate([Prop({
  required: true
})], VersioningVideoDownloadButton.prototype, "fileName", void 0);
VersioningVideoDownloadButton = __decorate([Component({
  components: {
    VersioningVideoDownloadModal,
    ConfirmDialog
  }
})], VersioningVideoDownloadButton);
export default VersioningVideoDownloadButton;