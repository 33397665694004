import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import BigButton from "@/components/buttons/BigButton.vue";
import WInput from "@/components/form/w-input.vue";
import axios from "axios";
import { alertError } from "@/util/dialog";
import Loader from "@/components/loader/Loader.vue";
import { waitingJob } from "@/util/job.utils";
import LightButton from "@/components/buttons/LightButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import AIChapterContentGenerator from "@/components/aiSpeachGenerator/AIChapterContentGenerator.vue";
import { toSpeachEditingPage } from "@/util/routeUtil";
import AISpeachGeneratorPanelOption from "@/components/aiSpeachGenerator/AISpeachGeneratorPanelOption.vue";
import delay from "delay";
let AIStepsGenerator = class AIStepsGenerator extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "aiFiles", []);
    _defineProperty(this, "uploadedFiles", []);
    _defineProperty(this, "language", void 0);
    _defineProperty(this, "duration", void 0);
    _defineProperty(this, "specificPrompt", void 0);
    _defineProperty(this, "allowReorder", void 0);
    _defineProperty(this, "weetEndpoint", void 0);
    _defineProperty(this, "languageSelected", "");
    _defineProperty(this, "durationSelected", "");
    _defineProperty(this, "specificPromptSelected", "");
    _defineProperty(this, "allowReorderSelected", false);
    _defineProperty(this, "openElement", []);
    _defineProperty(this, "generationInProgress", true);
    _defineProperty(this, "showOptionPanel", false);
    _defineProperty(this, "speachGenerated", null);
    _defineProperty(this, "speachToMap", null);
    _defineProperty(this, "indexLecon", -1);
    _defineProperty(this, "indexChapter", -1);
    _defineProperty(this, "weetIDMap", {});
  }
  mounted() {
    this.languageSelected = this.language;
    this.durationSelected = this.duration;
    this.allowReorderSelected = this.allowReorder;
    this.specificPromptSelected = this.specificPrompt;
    this.generateLLMSteps();
  }
  generateLLMSteps() {
    this.generationInProgress = true;
    const formData = new FormData();
    this.aiFiles.forEach(el => {
      formData.append('jobIDs', el.jobID);
    });
    formData.append("language", this.languageSelected);
    formData.append("duration", this.durationSelected);
    formData.append("specificPrompt", this.specificPromptSelected);
    formData.append("allowReorder", this.allowReorderSelected + "");
    axios.post('/recorder/aiSpeachGenerator/generateSteps', formData).then(async resp => {
      this.waitStepGenerationProceed(resp.data);
    }).catch(e => {
      this.generationInProgress = false;
      console.error(e);
      alertError(this.$t('aiContent.error'));
    });
  }
  openAllElement() {
    this.openElement = [];
    if (this.speachGenerated) {
      let i = 0;
      for (const chapter of (_this$speachGenerated = this.speachGenerated) === null || _this$speachGenerated === void 0 ? void 0 : _this$speachGenerated.chapters) {
        var _this$speachGenerated;
        this.openElement.push("chapter" + i);
        i++;
      }
    }
  }
  regenerate() {
    this.showOptionPanel = false;
    this.generateLLMSteps();
  }
  async waitStepGenerationProceed(jobID) {
    try {
      const data = await waitingJob(jobID);
      this.speachGenerated = data;
      this.openAllElement();
      this.generationInProgress = false;
    } catch (e) {
      console.error(e);
      alertError(this.$t('aiContent.error'));
    }
  }
  async validateAiSpeach(goToEditing) {
    if (this.speachToMap) {
      document.location = toSpeachEditingPage(this.speachToMap.speachID);
    } else {
      this.generationInProgress = true;
      axios.post('/recorder/aiSpeachGenerator/validateSteps', this.speachGenerated).then(async resp => {
        this.speachToMap = resp.data;
        if (goToEditing) {
          document.location = toSpeachEditingPage(this.speachToMap.speachID);
        }
        await delay(1000);
        this.generationInProgress = false;
      }).catch(e => {
        console.error(e);
        alertError(this.$t('aiContent.error'));
      });
    }
  }
  async launchChapterGeneration(indexChapter, indexSubchapter) {
    if (!this.speachToMap) {
      return;
    }
    this.indexLecon = indexChapter;
    this.indexChapter = indexSubchapter;
  }
  onCloseContentGeneration(weetID) {
    this.weetIDMap[this.indexLecon + "-" + this.indexChapter] = weetID;
    this.indexChapter = -1;
    this.indexLecon = -1;
  }
  getWeetIdForChapter(indexlecon, indexChapter) {
    return this.weetIDMap[indexlecon + "-" + indexChapter];
  }
  getOriginalFileForName(name) {
    const indexDoc = name.split("_")[1];
    if (indexDoc) {
      const index = parseInt(indexDoc, 10);
      return this.uploadedFiles[index - 1];
    }
    return null;
  }
  getDocumentForName(name) {
    const indexDoc = name.split("_")[1];
    if (indexDoc) {
      const index = parseInt(indexDoc, 10);
      return this.aiFiles[index - 1];
    }
    return null;
  }
  getPageImage(page) {
    const doc = this.getDocumentForName(page.documentName);
    if (doc && doc.data.length > page.pageNumber - 1) {
      return "data:image/jpeg;base64," + doc.data[page.pageNumber - 1].imageB64;
    }
    return "";
  }
  prevSlide(ref) {
    // @ts-ignore
    this.$refs[ref][0].prev();
  }
  nextSlide(ref) {
    // @ts-ignore
    this.$refs[ref][0].next();
  }
  onDurationChange(value) {
    this.durationSelected = value;
  }
  onLanguageChange(value) {
    this.languageSelected = value;
  }
  onAllowReorder(value) {
    this.allowReorderSelected = value;
  }
  onSpecificPromptChange(value) {
    this.specificPromptSelected = value;
  }
};
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "aiFiles", void 0);
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "uploadedFiles", void 0);
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "language", void 0);
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "duration", void 0);
__decorate([Prop({
  default: ""
})], AIStepsGenerator.prototype, "specificPrompt", void 0);
__decorate([Prop({
  default: true
})], AIStepsGenerator.prototype, "allowReorder", void 0);
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "weetEndpoint", void 0);
AIStepsGenerator = __decorate([Component({
  components: {
    AISpeachGeneratorPanelOption,
    AIChapterContentGenerator,
    WDialog,
    IconButton,
    LightButton,
    Loader,
    WInput,
    BigButton,
    StandardButton,
    Icon
  }
})], AIStepsGenerator);
export default AIStepsGenerator;