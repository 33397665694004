import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { __decorate } from "tslib";
import shuffle from "lodash/shuffle";
import { Component, Prop, Vue } from "vue-facing-decorator";
import AuthorIconGroupPicto from "@/components/card/authorIcon/authorIconGroup/AuthorIconGroupPicto.vue";
let AuthorIconGroup = class AuthorIconGroup extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "author", void 0);
    _defineProperty(this, "coEditors", void 0);
    _defineProperty(this, "coEditorGroups", void 0);
    _defineProperty(this, "maxAuthorsDisplayed", void 0);
    _defineProperty(this, "size", void 0);
  }
  get numberOfCoEditorsNotDisplayed() {
    if (this.numberOfCoAuthorsSlotsLeft >= this.coEditors.length) {
      return 0;
    } else {
      return this.coEditors.length - this.numberOfCoAuthorsSlotsLeft;
    }
  }
  get numberOfCoAuthorsSlotsLeft() {
    let numberOfGroupsToDisplay = 0;
    if (this.coEditorGroups.length > 0) {
      numberOfGroupsToDisplay = 1;
    }
    let numberOfCoAuthorsSlotsLeft = this.maxAuthorsDisplayed - 1 - numberOfGroupsToDisplay;
    return numberOfCoAuthorsSlotsLeft;
  }
  // Retourne les auteurs à afficher suivant les règles suivantes:
  // - L'auteur
  // - Les utilisateurs coauteurs jusqu'a la taille max à afficher - le potentiel groupe ci-après (3 par défaut)
  // - Si il il y a seulement un groupe le groupe
  // - Si il y  a plusieurs groupes une icone groupe + le label "n groupes"
  get authors() {
    let computedAuthors = [Object.assign(this.author, {
      type: "author"
    })];
    let coEditorGroupsWithClass = this.coEditorGroups.map(group => Object.assign(group, {
      type: "group"
    }));
    let coEditorsWithClassInRandomOrder = shuffle(this.coEditors.map(coEditor => Object.assign(coEditor, {
      type: "author"
    })));
    computedAuthors = computedAuthors.concat(coEditorsWithClassInRandomOrder.slice(0, this.numberOfCoAuthorsSlotsLeft));
    if (coEditorGroupsWithClass.length > 0) {
      if (coEditorGroupsWithClass.length == 1) {
        computedAuthors.push(coEditorGroupsWithClass[0]);
      } else {
        computedAuthors.push({
          title: this.$t("author.message.multi.groupe", {
            groupCount: coEditorGroupsWithClass.length
          }),
          // @ts-ignore //remove after type by user
          imgUrl: coEditorGroupsWithClass[0].imgUrl,
          type: "group"
        });
      }
    }
    return computedAuthors;
  }
  get authorsInfosReversed() {
    return this.authors.slice().reverse();
  }
};
__decorate([Prop({
  required: true
})], AuthorIconGroup.prototype, "author", void 0);
__decorate([Prop({
  default: []
})], AuthorIconGroup.prototype, "coEditors", void 0);
__decorate([Prop({
  default: []
})], AuthorIconGroup.prototype, "coEditorGroups", void 0);
__decorate([Prop({
  default: 3
})], AuthorIconGroup.prototype, "maxAuthorsDisplayed", void 0);
__decorate([Prop({
  default: 24
})], AuthorIconGroup.prototype, "size", void 0);
AuthorIconGroup = __decorate([Component({
  components: {
    AuthorIconGroupPicto
  }
})], AuthorIconGroup);
export default AuthorIconGroup;