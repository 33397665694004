import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import GridLayout from "@/components/layout/GridLayout.vue";
import GetStartedCard from "@/components/getStartedBlock/GetStartedCard.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let GetStartedBlock = class GetStartedBlock extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "showCreateSpeachCard", void 0);
    _defineProperty(this, "showCreateUserCard", void 0);
    _defineProperty(this, "showCreateSpeachAICard", void 0);
    _defineProperty(this, "numberOfSpeachesAwaitingValidation", void 0);
    _defineProperty(this, "numberOfSpeachesToValidate", void 0);
    _defineProperty(this, "numberOfUnreadNotification", void 0);
    _defineProperty(this, "numberOfSpeachesToReview", void 0);
    _defineProperty(this, "numberOfPeriodicReviewsNearing", void 0);
    _defineProperty(this, "mutableShowCreateSpeachCard", true);
    _defineProperty(this, "mutableShowCreateUserCard", true);
  }
  mounted() {
    this.mutableShowCreateSpeachCard = this.showCreateSpeachCard;
    this.mutableShowCreateUserCard = this.showCreateUserCard;
  }
  get blockContainsAtLeastOneCard() {
    return this.mutableShowCreateSpeachCard || this.mutableShowCreateUserCard || this.numberOfSpeachesAwaitingValidation > 0 || this.numberOfSpeachesToValidate > 0 || this.numberOfUnreadNotification > 0;
  }
  removeCreateSpeachCard() {
    this.removeGetStartedCard("SPEACH").then(() => {
      this.mutableShowCreateSpeachCard = false;
    });
  }
  removeCreateUserCard() {
    this.removeGetStartedCard("USER").then(() => {
      this.mutableShowCreateUserCard = false;
    });
  }
  removeGetStartedCard(type) {
    return axios.get("/user.usercontroller/removegetstarted", {
      params: {
        type: type
      }
    }).then(() => {
      //
      // success(this.$t("get.started.remove.succes"));
    });
  }
  navigate(url) {
    window.location.assign(url);
  }
};
__decorate([Prop({
  default: true
})], GetStartedBlock.prototype, "showCreateSpeachCard", void 0);
__decorate([Prop({
  default: true
})], GetStartedBlock.prototype, "showCreateUserCard", void 0);
__decorate([Prop({
  default: false
})], GetStartedBlock.prototype, "showCreateSpeachAICard", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfSpeachesAwaitingValidation", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfSpeachesToValidate", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfUnreadNotification", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfSpeachesToReview", void 0);
__decorate([Prop({
  default: 0
})], GetStartedBlock.prototype, "numberOfPeriodicReviewsNearing", void 0);
GetStartedBlock = __decorate([Component({
  components: {
    GridLayout,
    GetStartedCard
  }
})], GetStartedBlock);
export default GetStartedBlock;