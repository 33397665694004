import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import GridLayout from "@/components/layout/GridLayout.vue";
import SidePanel from "@/components/layout/SidePanel.vue";
import NotificationParameters from "@/components/notifications/NotificationParameters.vue";
import NotificationList from "@/components/notifications/subcomponent/NotificationList.vue";
import WSelect from "@/components/form/w-select.vue";
import NotificationIcon from "@/components/notifications/subcomponent/NotificationIcon.vue";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import Loader from "@/components/loader/Loader.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import { WeetEventName } from "@/weet/weetEvent";
import { Events } from "@/events/events";
import { extractDomain } from "@/util/htmlUtils";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { getUrlPlayer } from "@/util/routeUtil";
let WeetPlayerChapter = class WeetPlayerChapter extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "weetAccessToken", void 0);
    //access token to use weet
    _defineProperty(this, "chapterJson", void 0);
    _defineProperty(this, "weetEndpoint", void 0);
    _defineProperty(this, "boutiqueId", void 0);
    _defineProperty(this, "chapter", void 0);
    _defineProperty(this, "weetID", "");
  }
  get urlPlayer() {
    return getUrlPlayer(this.weetEndpoint, this.weetAccessToken, this.boutiqueId, this.weetID);
  }
  mounted() {
    this.chapter = JSON.parse(this.chapterJson);
    this.weetID = this.chapter.videoID;
    if (!this.chapter) {
      console.error("Impossible to access weet without chapter");
      return;
    }
    if (!this.weetAccessToken) {
      console.error("Impossible to access weet without access token");
    }
    this.manageMessages();
  }
  manageMessages() {
    const onMessageReceive = this.onMessageReceive;
    window.addEventListener('message', onMessageReceive);
    const onEtape = function onEtape(event) {
      window.removeEventListener("message", onMessageReceive);
      unregister(Events.LOAD_ETAPE, onEtape);
    };
    register(Events.LOAD_ETAPE, onEtape);
  }
  onMessageReceive(event) {
    if (extractDomain(event.origin) === extractDomain(document.location.href) || extractDomain(event.origin) === extractDomain(this.weetEndpoint)) {
      const objData = event.data;
      if (objData.name === WeetEventName.WEET_PLAYING) {
        trig(Events.VIDEO_PLAYING);
      } else if (objData.name === WeetEventName.WEET_PAUSING) {
        trig(Events.VIDEO_PAUSING);
      } else if (objData.name === WeetEventName.WEET_END) {
        trig(Events.VIDEO_ENDED);
      }
    }
  }
  beforeDestroy() {
    window.removeEventListener("message", this.onMessageReceive);
  }
};
__decorate([Prop({
  required: true
})], WeetPlayerChapter.prototype, "weetAccessToken", void 0);
__decorate([Prop({
  required: true
})], WeetPlayerChapter.prototype, "chapterJson", void 0);
__decorate([Prop({
  required: true
})], WeetPlayerChapter.prototype, "weetEndpoint", void 0);
__decorate([Prop({
  required: true
})], WeetPlayerChapter.prototype, "boutiqueId", void 0);
WeetPlayerChapter = __decorate([Component({
  components: {
    WDialog,
    Loader,
    WToolTip,
    Icon,
    NotificationIcon,
    WSelect,
    NotificationList,
    NotificationParameters,
    SidePanel,
    GridLayout
  }
})], WeetPlayerChapter);
export default WeetPlayerChapter;