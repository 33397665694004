import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.every.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.some.js";
import { __decorate } from "tslib";
import WSelectUsers from "@/components/form/w-select-users.vue";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WAlert from "@/components/wrappers/w-alert.vue";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import WTag from "@/components/wrappers/w-tag.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
let BatchUpdateUser = class BatchUpdateUser extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "usersToUpdate", void 0);
    _defineProperty(this, "workflowEnabled", void 0);
    _defineProperty(this, "availableGroups", []);
    _defineProperty(this, "displayedGroups", []);
    _defineProperty(this, "noGroupSelected", false);
    _defineProperty(this, "noRoleSelected", false);
    _defineProperty(this, "roleAuthor", 'FORMATEUR');
    _defineProperty(this, "roleApprover", 'APPROVER');
    _defineProperty(this, "norole", 'NOROLE');
    _defineProperty(this, "displayedRoles", []);
    _defineProperty(this, "fieldsToUpdate", {
      groups: undefined,
      roles: undefined
    });
    _defineProperty(this, "confirmUpdatePopup", false);
    _defineProperty(this, "BASE_API_USERS", '/api/v2/admin/users');
  }
  mounted() {
    this.initGroups();
    this.displayedRoles = this.possibleRoles;
    this.fieldsToUpdate.roles = this.alreadySelectedRoles;
  }
  initGroups() {
    axios.get(this.BASE_API_USERS + '/groups').then(response => {
      this.availableGroups = response.data.filter(group => !group.system).sort((a, b) => {
        if (a.system) {
          return -1;
        } else {
          if (b.system) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      const noGroup = {
        id: -1,
        name: this.$t('admin.dashboard.users.noGroup'),
        system: false,
        order: -1,
        description: ""
      };
      this.availableGroups.unshift(noGroup);
      this.displayedGroups = this.availableGroups;
      this.fieldsToUpdate.groups = this.alreadySelectedGroups;
    });
  }
  get alreadySelectedGroups() {
    if (this.noGroupSelected) {
      return [-1];
    } else {
      // Filter the groups that are present in every user's groups by comparing their "id"
      return this.availableGroups.filter(group => this.usersToUpdate.every(user => user.groups.some(userGroup => userGroup.id === group.id))).map(group => group.id);
    }
  }
  get possibleRoles() {
    let roles = [{
      name: this.$t('admin.dashboard.users.role.noRole'),
      key: this.norole
    }, {
      name: this.$t('admin.dashboard.users.role.author'),
      key: this.roleAuthor
    }];
    if (this.workflowEnabled) {
      roles.push({
        name: this.$t('admin.dashboard.users.role.approver'),
        key: this.roleApprover
      });
    }
    return roles;
  }
  get alreadySelectedRoles() {
    if (this.noRoleSelected) {
      return [this.norole];
    } else {
      const rolesSetForEverySelectedUser = this.possibleRoles.filter(role => {
        if (role.key === this.roleAuthor) {
          return this.usersToUpdate.every(user => user.trainer);
        } else if (role.key === this.roleApprover) {
          return this.usersToUpdate.every(user => user.approver);
        }
      });
      return rolesSetForEverySelectedUser.map(role => role.key);
    }
  }
  onChangeGroups(value) {
    if (value.includes(-1)) {
      this.displayedGroups = this.displayedGroups.filter(group => group.id === -1);
      this.fieldsToUpdate.groups = [-1];
      this.noGroupSelected = true;
    } else {
      this.displayedGroups = this.availableGroups;
      this.fieldsToUpdate.groups = value;
      this.noGroupSelected = false;
    }
  }
  deleteOneGroup(groupIdToDelete) {
    var _this$fieldsToUpdate$;
    this.fieldsToUpdate.groups = (_this$fieldsToUpdate$ = this.fieldsToUpdate.groups) === null || _this$fieldsToUpdate$ === void 0 ? void 0 : _this$fieldsToUpdate$.filter(groupId => groupId !== groupIdToDelete);
  }
  groupName(groupId) {
    return this.availableGroups.filter(group => group.id === groupId).map(group => group.name)[0];
  }
  onChangeRoles(value) {
    if (value.includes(this.norole)) {
      this.displayedRoles = this.displayedRoles.filter(role => role.key === this.norole);
      this.fieldsToUpdate.roles = [this.norole];
      this.noRoleSelected = true;
    } else {
      this.displayedRoles = this.possibleRoles;
      this.fieldsToUpdate.roles = value;
      this.noRoleSelected = false;
    }
  }
  roleName(roleKey) {
    return this.possibleRoles.find(role => role.key === roleKey).name;
  }
  deleteOneRole(roleKeyToDelete) {
    var _this$fieldsToUpdate$2;
    this.fieldsToUpdate.roles = (_this$fieldsToUpdate$2 = this.fieldsToUpdate.roles) === null || _this$fieldsToUpdate$2 === void 0 ? void 0 : _this$fieldsToUpdate$2.filter(roleKey => roleKey !== roleKeyToDelete);
    if (roleKeyToDelete === this.norole) {
      this.noRoleSelected = false;
      this.displayedRoles = this.possibleRoles;
      this.fieldsToUpdate.roles = this.alreadySelectedRoles;
    }
  }
  submitBatchUpdate() {
    if (!this.fieldsToUpdate) {
      return;
    }
    axios.put(this.BASE_API_USERS + '/batch-update', {
      usersIds: this.usersToUpdate.map(user => `${user.id}`)
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        dataType: "json",
        groupsIds: this.fieldsToUpdate.groups,
        roles: this.fieldsToUpdate.roles
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.users.toasts.usersBatchUpdated"));
      this.$emit('batchUpdateSuccess');
    }, error => {
      const usersEmails = this.usersToUpdate.filter(user => error.message.includes(user.id)).map(user => user.email);
      WElMessage.showLongErrorMessage(this.$t("admin.dashboard.users.toasts.usersBatchUpdateFailed", {
        usersFailed: usersEmails
      }));
    });
  }
  cancelBatchUpdate() {
    this.$emit('batchUpdateCancel');
  }
};
__decorate([Prop({
  default: []
})], BatchUpdateUser.prototype, "usersToUpdate", void 0);
__decorate([Prop({
  default: false
})], BatchUpdateUser.prototype, "workflowEnabled", void 0);
BatchUpdateUser = __decorate([Component({
  components: {
    ConfirmDialog,
    WDialog,
    WTag,
    WSelectAdvanced,
    WAlert,
    StandardButton,
    WSelectUsers
  },
  emits: ['batchUpdateSuccess', 'batchUpdateCancel']
})], BatchUpdateUser);
export default BatchUpdateUser;