import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8235f0b6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "notificationContainer"
};
const _hoisted_2 = {
  class: "contentNotification"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "date"
};
const _hoisted_5 = {
  key: 0,
  class: "textContent"
};
const _hoisted_6 = {
  key: 1,
  class: "textContent"
};
const _hoisted_7 = {
  key: 0,
  class: "ratingContent"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AuthorIconGroupPicto = _resolveComponent("AuthorIconGroupPicto");
  const _component_icon = _resolveComponent("icon");
  const _component_rating = _resolveComponent("rating");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_AuthorIconGroupPicto, {
    class: "authorIcon",
    user: _ctx.notification.sourceUser,
    size: 48
  }, null, 8, ["user"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "textNotification",
    innerHTML: _ctx.messageTitle
  }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createVNode(_component_icon, {
    icon: "clock-outline",
    color: "dark"
  }), _createTextVNode(" " + _toDisplayString(_ctx.date), 1)]), _ctx.notification.message ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.notification.message.content), 1)) : _createCommentVNode("", true), _ctx.notification.commentaire ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_ctx.notification.commentaire.rating > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_rating, {
    value: _ctx.notification.commentaire.rating
  }, null, 8, ["value"])])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(_ctx.notification.commentaire.content), 1)])) : _createCommentVNode("", true)]), this.notification.speach ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "imageSpeach",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.goToSpeach && _ctx.goToSpeach(...args)),
    style: _normalizeStyle('background-image:url(' + this.notification.speach.img + ')')
  }, null, 4)) : _createCommentVNode("", true), _ctx.notification.parcours ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "imageSpeach",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.goToParcours && _ctx.goToParcours(...args))
  }, [_createElementVNode("div", {
    style: _normalizeStyle({
      'background-image': this.notification.parcours.image
    })
  }, null, 4)])) : _createCommentVNode("", true)]);
}