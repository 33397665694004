import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-facing-decorator";
import ContentTile from "@/components/dashboard/tiles/ContentTile.vue";
import Tile from "@/components/dashboard/tiles/Tile.vue";
import { getAdmins, getAiEditors, getApprovers, getSpeachMakers, getUsers, isWorkflowEnable } from "@/util/dashboardUtils";
let DashboardUsersTiles = class DashboardUsersTiles extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "users", 0);
    _defineProperty(this, "admins", 0);
    _defineProperty(this, "speachmakers", 0);
    _defineProperty(this, "aiEditors", 0);
    _defineProperty(this, "approvers", 0);
    _defineProperty(this, "showApprovers", false);
  }
  get tileWidth() {
    return this.showApprovers ? 25 : 33;
  }
  mounted() {
    getUsers().then(res => this.users = res);
    getAdmins().then(res => this.admins = res);
    getSpeachMakers().then(res => this.speachmakers = res);
    getAiEditors().then(res => this.aiEditors = res);
    isWorkflowEnable().then(res => {
      this.showApprovers = res;
      if (this.showApprovers) {
        getApprovers().then(res => this.approvers = res);
      }
    });
  }
};
DashboardUsersTiles = __decorate([Component({
  components: {
    Tile,
    ContentTile
  }
})], DashboardUsersTiles);
export default DashboardUsersTiles;